import { Button, Space } from "antd"
import { Icons } from "../ui/Icons";
import { MapActions } from "../helpers/MapActions";
import SearchBox from "../ui/SearchBox";
import { useWindowContext } from "../../../contexts/WindowContext";
import { EventNames, routeFinderEventEmitter } from "../helpers/EventEmitter";
import { useMapContext } from "../../../contexts/MapContext";
import ReactGA from 'react-ga4'

export const NormalmodeMapControls = () => {

    const { isMobile } = useWindowContext();
    const { mapState, updateMapState } = useMapContext();

    const showRouteFinder = () => {
        updateMapState({
            ...mapState,
            clickedPolylineId: 0
        })
        routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: true })
    }

    return <Space direction="horizontal" className="full-width">
        <Button
            icon={Icons.DrawRouteModeIcon}
            onClick={() => {
                ReactGA.event({
                    category: 'Map Controls',
                    action: 'Click',
                    label: `Draw Route`
                });
                MapActions.beginDrawRouteMode()
            }}
            size="large"
            type="primary">
            {isMobile ? '' : 'Draw Route'}
        </Button>
        <SearchBox />
        <Button
            onClick={() => {
                showRouteFinder();
                ReactGA.event({
                    category: 'Map Controls',
                    action: 'Click',
                    label: `Route Finder`
                });
            }}
            icon={Icons.RouteFinderIcon}
            size="large"
            type="primary">
            {isMobile ? '' : 'Route Finder'}
        </Button>
    </Space>
}

export default NormalmodeMapControls