import { useEffect, useState } from "react";

import { App, Badge, Button, Divider, Table, TableColumnsType, Tag, Tooltip } from "antd";
import { approveRoute, deletePolyline } from "../../client/mapclient";
import { themeStyles } from "../../constants/theme";
import { useAppContext } from "../../contexts/AppContext";
import { useMapContext } from "../../contexts/MapContext";
import { ThemeHelpers } from "../../helpers/ThemeHelpers";
import { PolylineType, IPolylineModel } from "../../models/map/PolylineModel";
import { MapHelpers } from "../map/helpers/MapHelpers";
import { Icons } from "../map/ui/Icons";
import { UserType } from "../../models/general/User";
import { useNavigate } from "react-router-dom";
import { useWindowContext } from "../../contexts/WindowContext";


interface IMyRoutesItem {
    key: string,
    id: number,
    polylineType: PolylineType,
    name: string,
    length: string,
    pending: boolean
}

export const SubmissionsRoutes = () => {
    const [myRoutes, setMyroutes] = useState<IPolylineModel[]>([])
    const navigate = useNavigate();
    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext();
    const app = App.useApp();
    const { isMobile } = useWindowContext();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const account = appState.account;

        let routes = mapState.polylines.filter(p => {
            if (account.userType === UserType.Public) {
                // Get only routes belonging to user
                return account.routeIDs.includes(p.id)
            }
            else {
                // For Admin, get all
                return true
            }
        });
        routes = routes.sort((a, b) => {
            if (a.pending !== b.pending) {
                return a.pending ? -1 : 1;
            } else {
                return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
            }
        })

        setMyroutes(routes);
    }, [mapState, appState])

    const removeRouteFromAccount = (id: number) => {
        let userRoutes = appState.account.routeIDs.filter(i => i !== id)
        updateAppState({
            ...appState,
            account: {
                ...appState.account,
                routeIDs: userRoutes
            }
        })
    }
    const removeRouteFromMap = (id: number) => {
        let polylines = mapState.polylines.filter(p => p.id !== id);
        updateMapState({
            ...mapState,
            polylines: polylines
        })
    }

    const deleteRoute = async (id: number) => {
        setLoading(true);
        const result = await deletePolyline(id);
        if (result.isSuccess) {
            removeRouteFromAccount(id);
            removeRouteFromMap(id);
            app.notification.info({
                message: 'Route Deleted',
                placement: 'top'
            })
        }
        else {
            app.notification.error({
                message: 'Deleting Failed',
                placement: 'top'
            })
        }
        setLoading(false)
    }

    const onDeleteRouteClicked = (id: number): void => {

        app.modal.confirm({
            title: 'Delete submission',
            content: 'Are you sure?',
            centered: true,
            cancelText: 'Cancel',
            closeIcon: null,
            maskClosable: true,
            okButtonProps: {
                style: {
                    backgroundColor: themeStyles.colorWarn
                }
            },
            okText: 'Confirm',
            onOk: (close) => {
                deleteRoute(id)
                close();
            }
        })
    }

    const shouldDisableDeleteBtn = (pending: boolean) => {
        if (appState.account.userType === UserType.Admin) {
            return false;
        }
        if (!pending) {
            return true;
        }
    }

    const onApproveRouteClicked = async (id: number) => {
        setLoading(true);
        const result = await approveRoute(id);
        if (result.isSuccess) {
            let polylines = [...mapState.polylines];
            let p = polylines.find(x => x.id === id)
            if (p) {
                let index = polylines.indexOf(p)
                p.pending = false;
                polylines.splice(index, 0, p);
                updateMapState({
                    ...mapState,
                    polylines: polylines
                })
                app.notification.info({
                    message: 'Route Approved',
                    placement: 'top'
                })
            }
        }
        else {
            app.notification.error({
                message: 'Route Failed',
                placement: 'top'
            })
        }
        setLoading(false)
    }

    const onShowOnMapClicked = (id: number) => {
        updateMapState({
            ...mapState,
            clickedPolylineId: id
        })
        navigate(`/map?routeId=${id}`);
    }

    const tableColumns: TableColumnsType<IMyRoutesItem> = [
        {
            title: 'Type',
            align: 'center',
            dataIndex: 'polylineType',
            key: 'polylineType',
            render: (type: PolylineType) => {
                return <>
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip title={MapHelpers.getRouteTypeLabel(type)}>
                            <Tag color={ThemeHelpers.getRouteColour(type)}>
                                {MapHelpers.getRouteTypeIcon(type)}
                            </Tag>
                        </Tooltip>
                    </div>
                </>
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Length',
            dataIndex: 'length',
            key: 'length',
        },
        {
            title: 'Status',
            dataIndex: 'pending',
            key: 'pending',
            render: (pending: boolean) => <>
                {
                    pending
                        ? <Tooltip title={isMobile ? 'Pending' : ''}>
                            <Tag color={themeStyles.colorPrimary}>{isMobile ? Icons.PendingIcon : 'Pending'}</Tag>
                        </Tooltip>
                        : <Tooltip title={isMobile ? 'Published' : ''}>
                            <Tag color={themeStyles.colorSuccess}>{isMobile ? Icons.CheckMarkIcon : 'Published'}</Tag>
                        </Tooltip>
                }
            </>
        },
        {
            title: 'Locate',
            key: 'flyto',
            align: 'center',
            render: (item: IMyRoutesItem) =>
                <Button
                    type="default"
                    icon={Icons.RouteFinderIcon}
                    onClick={() => onShowOnMapClicked(item.id)}
                />
        },
        {
            title: '',
            key: 'delete',
            align: 'center',
            render: (item: IMyRoutesItem) =>
                <Tooltip title={item.pending || appState.account.userType === UserType.Admin ? 'Delete' : 'Cannot delete published routes'}>
                    <Button
                        type="default"
                        color="red"
                        disabled={shouldDisableDeleteBtn(item.pending)}
                        icon={Icons.DeleteIcon}
                        onClick={() => onDeleteRouteClicked(item.id)}
                    />
                </Tooltip>
        },
        {
            title: '',
            key: 'approve',
            align: 'center',
            hidden: appState.account.userType !== UserType.Admin,
            render: (item: IMyRoutesItem) => appState.account.userType === UserType.Admin &&
                <Button
                    disabled={!item.pending}
                    type="primary"
                    icon={Icons.CheckMarkIcon}
                    onClick={() => onApproveRouteClicked(item.id)}
                />
        }
    ]

    let tableData = myRoutes.map((r, i) => {
        return {
            id: r.id,
            polylineType: r.polylineType,
            name: r.name,
            length: MapHelpers.getPolylineLength(r),
            pending: r.pending,
            key: `routes-table-${r.id}`
        }
    })

    return <Table
        loading={loading}
        pagination={{ pageSize: 10 }}
        bordered size="small"
        dataSource={tableData}
        columns={tableColumns} />
}

export default SubmissionsRoutes