import { Popup, useMapEvents } from "react-leaflet"
import { useMapContext } from "../../../contexts/MapContext";
import { MapInteractionMode } from "../../../models/map/MapState";
import { NormalModeContextMenu } from "./NormalModeContextMenu";
import DrawRouteModeContextMenu from "./DrawRouteModeContextMenu";
import AddImageContextMenu from "./AddImageContextMenu";
import ReactGA from 'react-ga4'

export const MapContextMenu = () => {

    const { mapState, updateMapState } = useMapContext();

    useMapEvents({

        contextmenu(e) {
            e.originalEvent.preventDefault();

            updateMapState({
                ...mapState,
                showContextMenu: true,
                contextMenuPos: e.latlng
            });

            ReactGA.event({
                category: 'Map Interaction',
                action: `Context Menu`,
                label: `${MapInteractionMode[mapState.interactionMode]}`
            });
        },
    })


    let menu: JSX.Element;

    switch (mapState.interactionMode) {
        case MapInteractionMode.Normal: menu = <NormalModeContextMenu />; break;
        case MapInteractionMode.DrawRoute: menu = <DrawRouteModeContextMenu />; break;
        case MapInteractionMode.AddImage: menu = <AddImageContextMenu />; break;
        default: menu = <NormalModeContextMenu />; break;
    }


    if (!mapState.showContextMenu || !mapState.contextMenuPos)
        return <></>

    return <Popup
        autoPan
        position={mapState.contextMenuPos}
        closeButton={false}>
        {menu}
    </Popup>
}