import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { IUserModel, UserModel } from '../models/general/User';
import { LatLng } from 'leaflet';
import { MapHelpers } from '../components/map/helpers/MapHelpers';
import { getUserData } from '../client/accountClient';
import { MapLayer } from '../components/map/helpers/MapLayer';

export interface IAppState {
    isLoading: boolean
    account: IUserModel
    mapCentre: LatLng
}

const appInitialState: IAppState = {
    isLoading: true,
    account: new UserModel(),
    mapCentre: MapLayer.center_UK
}

const AppContext = createContext<{ appState: IAppState; updateAppState: (newState: IAppState) => void }>({
    appState: appInitialState,
    updateAppState: () => { }
});

// Create a provider component
export const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [appState, setAppState] = useState<IAppState>(appInitialState);

    const updateAppState = (newState: IAppState) => {
        setAppState(newState);
    };

    return (
        <AppContext.Provider value={{ appState, updateAppState }}>
            {children}
        </AppContext.Provider>
    );
}

export const useAppContext = () => useContext(AppContext);