import { gold, blue, magenta, gray, green, red, purple } from '@ant-design/colors';
import icon from "leaflet/dist/images/marker-icon.png";

export const themeConstants = {
    iconAnchor: {
        x: 12,
        y: 40
    },
}

const colorPrimary = '#c9306c';
const colorError = '#830644';
const colorWarn = '#830644';
const colorSucces = '#02c99b';
const colorInfo = '#1889c9';

export const themeStyles = {
    colorPrimary: colorPrimary,
    colorError: colorError,
    colorWarn: colorWarn,
    colorSuccess: colorSucces,
    colorInfo: colorInfo,

    cursor: {
        normalMode: 'grab',
        drawRouteMode: `url('${icon}') ${themeConstants.iconAnchor.x} ${themeConstants.iconAnchor.y}, auto` //'crosshair'
    },
    mapBorder: {
        default: gray[0],
        editingMode: colorPrimary
    },
    buttonStyles: {
        warning: {
            backgroundColor: gold[5], color: 'white',
        } as React.CSSProperties
    },
    routeColours: {
        default: colorPrimary,
        offRoad: green[7],
        mountainBike: magenta[5],
        hiking: blue[5],
        dirtbike: '#fa9114'
    }

}