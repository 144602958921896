import { ChangeEvent, useState } from "react"
import { useMapContext } from "../../../contexts/MapContext";
import { Input } from "antd";
import { LatLng } from "leaflet";
import { MapHelpers } from "../helpers/MapHelpers";
import { useWindowContext } from "../../../contexts/WindowContext";
import { EventNames, routeFinderEventEmitter } from "../helpers/EventEmitter";
import { MapLayer } from "../helpers/MapLayer";
import ReactGA from 'react-ga4'

export interface ISearchResultItem {
    name: string,
    region: string,
    districtBorough: string,
    populatedPlace: string,
    county: string,
    country: string,
    localType: string,
    position: L.LatLng
}


interface IProps {
    className?: string;
}

const SearchBox = (props: IProps) => {
    const { mapState, updateMapState } = useMapContext();
    const [queryText, setQueryText] = useState<string>('');
    const [searching, setSearching] = useState(false);
    const { isMobile } = useWindowContext();

    const hasQuery = () => {
        return queryText && queryText.length > 0;
    }

    const getResults = (json: { results: any[] }): ISearchResultItem[] => {
        // Create an empty GeoJSON FeatureCollection.
        const results: ISearchResultItem[] = [];

        json.results.forEach((val, i) => {
            let result = val.GAZETTEER_ENTRY;

            let coords: number[] = MapHelpers.transformCoords([result.GEOMETRY_X, result.GEOMETRY_Y]);

            results.push({
                name: result.NAME1,
                localType: result.LOCAL_TYPE,
                country: result.COUNTRY,
                region: result.REGION,
                position: new LatLng(coords[0], coords[1]),
                districtBorough: result.DISTRICT_BOROUGH,
                county: result.COUNTY_UNITARY,
                populatedPlace: result.POPULATED_PLACE
            })
        });


        return results;
    }

    const submitQuery = async () => {
        if (hasQuery()) {
            updateMapState({
                ...mapState,
                searchResults: []
            })
            setSearching(true);
            ReactGA.event({
                category: 'Map Controls',
                action: 'Search',
                label: queryText
            });
            const url = `https://api.os.uk/search/names/v1/find?query=${queryText}&key=${MapLayer.osMapApiKey}`;
            const response = await fetch(url)
            if (response.ok) {
                const json = await response.json();
                if (json && json.header && json.header.totalresults > 0) {
                    const results = getResults(json);
                    updateMapState({
                        ...mapState,
                        searchResults: results
                    })
                    routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: false })
                }
            }
            setSearching(false);
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (searching) {
            return;
        }
        setQueryText(event.target.value);
    };

    const searchOnEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            await submitQuery();
        }
    }

    const inputStyle: React.CSSProperties = {
        backgroundColor: searching ? 'grey' : 'white',
        width: isMobile ? 'calc(100vw - 127px)' : '437px'
    }


    return <Input.Search
        className={props.className}
        size="large"
        placeholder="Search map"
        enterButton
        style={inputStyle}
        value={queryText}
        disabled={searching}
        onKeyUp={searchOnEnter}
        onChange={onChange}
        onSearch={submitQuery} />
}

export default SearchBox