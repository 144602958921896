import { useEffect, useState } from "react";
import { useMapContext } from "../../../contexts/MapContext";
import { Popup, useMap, useMapEvents } from "react-leaflet";
import { MapHelpers } from "../helpers/MapHelpers";
import { Badge, DescriptionsProps, Image, Tooltip, Typography } from "antd";
import { useWindowContext } from "../../../contexts/WindowContext";
import { PolylineImage } from "../../../models/map/PolylineImage";
import { themeStyles } from "../../../constants/theme";
import { useAppContext } from "../../../contexts/AppContext";
import { IPolylineModel, TRO_Type } from "../../../models/map/PolylineModel";
import RouteDetails from "../ui/RouteDetails";
import { MapLayer } from "../helpers/MapLayer";
import { UserType } from "../../../models/general/User";
import { LatLng } from "leaflet";
import { MapActions } from "../helpers/MapActions";
import '../../../css/map.css'
import '../../../css/app.css'
import { Icons } from "../ui/Icons";
import { DateHelpers } from "../../../helpers/DateHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4'

export const ClickedPolylineFeatures = () => {
    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext()
    const { isMobile } = useWindowContext();
    const [showImageMarkers, setShowImageMarkers] = useState(true)
    const [polyline, setPolyline] = useState<IPolylineModel | undefined>(undefined)
    const navigate = useNavigate();
    const location = useLocation();
    const map = useMap();

    useEffect(() => {
        const p = mapState.polylines.find(p => p.id === mapState.clickedPolylineId);
        setPolyline(p)
    }, [mapState])

    useMapEvents({
        zoom() {
            updateMapState({ ...mapState })
        },
        keyup(e) {
            if (polyline && appState.account.userType === UserType.Admin) {

                const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
                if (arrowKeys.includes(e.originalEvent.key)) {
                    e.originalEvent.preventDefault(); // Prevent default behavior of arrow keys (scrolling)
                    // Handle arrow key actions here
                    let dx = 0;
                    let dy = 0;
                    const increment = 5; // metre
                    switch (e.originalEvent.key) {
                        case 'ArrowUp':
                            dy = increment;
                            break;
                        case 'ArrowDown':
                            dy = -increment;
                            break;
                        case 'ArrowLeft':
                            dx = -increment;
                            break;
                        case 'ArrowRight':
                            dx = increment;
                            break;
                        default:
                            break;
                    }

                    // Update the polyline by shifting each latlng position
                    const shiftedPoints = polyline.points.map(p => {
                        const lat = p.lat + dy * (1 / 111111); // Convert meters to latitude increment (approximate)
                        const lng = p.lng + dx * (1 / (111111 * Math.cos((lat * Math.PI) / 180))); // Convert meters to longitude increment (approximate)
                        return new LatLng(lat, lng);
                    });
                    polyline.points = shiftedPoints;
                    setPolyline(polyline);
                }
                if (e.originalEvent.key === 'Enter') {
                    MapActions.updatePolyline(polyline);
                }
            }
        }
    })

    const closeDrawer = () => {
        updateMapState({
            ...mapState,
            clickedPolylineId: 0
        })
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('q');
        queryParams.delete('routeId');
        navigate(`${location.pathname}?${queryParams.toString()}`);
        ReactGA.event({
            category: 'Toggle',
            action: 'Route Details',
            label: 'Closed'
        });
    };

    const p = polyline;

    if (!p)
        return <></>

    const details: DescriptionsProps['items'] = [
        {
            key: 'route-name',
            label: 'Name',
            children: <div>
                {p.name}
            </div>,
            span: 2,
        },
        {
            key: 'has-tro',
            label: <>
                <span>TRO </span>
                <Tooltip title={MapHelpers.TRO_Toolip}>
                    {Icons.HelpIcon}
                </Tooltip>
            </>,
            className: p.hasTRO ? 'descriptions-warning' : 'hidden',
            contentStyle: { display: p.hasTRO ? 'block' : 'none' },
            children: <div>
                {
                    p.trO_Type === TRO_Type.Permanent
                        ? <>PERMANENT</>
                        : <>Until {DateHelpers.formatDateDDMMMYYYY(new Date(p.trO_EndDate as Date))}</>
                }
            </div>,
            span: 2,
        },
        {
            key: 'route-length',
            label: 'Length',
            children: <div>
                {MapHelpers.getPolylineLength(p)}
            </div>,
            span: 2,
        },
        {
            key: 'road-type',
            label: 'Accessibility',
            className: p.roadType === null ? 'hidden' : '',
            contentStyle: { display: p.roadType === null ? 'none' : '' },
            children: <div>
                {MapHelpers.getRoadTypeLabel(p.roadType)}
            </div>,
            span: 2,
        },
        {
            key: 'navigation',
            label: 'Google Maps',
            children: <>
                <div>
                    <Typography.Link target="_blank" href={MapHelpers.getGoogleMapsLink(p.points[0])}>Start</Typography.Link>
                    <span style={{ margin: '0 10px' }}>  /  </span>
                    <Typography.Link target="_blank" href={MapHelpers.getGoogleMapsLink(p.points[p.points.length - 1])}>End</Typography.Link>
                </div>
            </>,
            span: 2,
        },
        {
            key: 'notes',
            label: 'Notes',
            children: <div style={{ whiteSpace: 'pre-line' }}>{p.notes}</div>,
            span: 1,
        },
        {
            key: 'winter-conditions',
            label: 'Winter conditions',
            children: <div style={{ whiteSpace: 'pre-line' }}>{p.winterConditions}</div>,
            span: 1,
        },
        {
            key: 'summer-conditions',
            label: 'Summer conditions',
            children: <div style={{ whiteSpace: 'pre-line' }}>{p.summerConditions}</div>,
            span: 1,
        }
    ]



    const createImagePreview = (img: PolylineImage, h: string, w: string, i: number) => {
        return <div style={{
            backgroundColor: 'white',
            borderRadius: '5px'
        }}>
            <Badge count={i + 1} size="small" color={themeStyles.colorPrimary}>
                <div style={{
                    height: h,
                    width: w
                }}>
                    <Image height={'100%'} width={'100%'} style={{ objectFit: 'cover', zIndex: 1004 }} src={`${process.env.REACT_APP_BASE_URL}${img.filePath}`} />
                </div>
            </Badge>
        </div>
    }


    return <>
        {
            showImageMarkers && !MapLayer.outsideRouteRenderLimit(map.getZoom()) && p.images.filter(x => !x.pending).map((x, i) =>
                <Popup key={`p-image-${p.id}-${x.id}`}
                    position={x.position}
                    autoClose={false}
                    closeOnClick={false}
                    autoPan={false}
                    closeOnEscapeKey={false}
                    closeButton={false}>
                    <div style={{
                        padding: 2,
                        borderRadius: '5px',
                        backgroundColor: MapHelpers.getRouteColour(p.polylineType)
                    }}>
                        {createImagePreview(x, '80px', '80px', i)}
                    </div>
                </Popup>)
        }
        <RouteDetails
            createImagePreview={createImagePreview}
            drawerItems={details}
            isMobile={isMobile}
            onClose={closeDrawer}
            polyine={p}
            setShowImageMarkers={setShowImageMarkers}
            showImageMarkers={showImageMarkers}
        />
    </>
}

export default ClickedPolylineFeatures