import { ISearchResultItem } from "../ui/SearchBox";

interface ListenerCallback<T> {
    (data: T): void;
}

class EventEmitter<T> {
    listeners: { [key: string]: ListenerCallback<T>[] } = {};

    on(event: string, callback: ListenerCallback<T>): void {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    off(event: string, callback: ListenerCallback<T>): void {
        if (this.listeners[event]) {
            this.listeners[event] = []//this.listeners[event].filter(cb => cb !== callback);
        }
    }

    emit(event: string, data: T): void {
        const eventListeners = this.listeners[event];
        if (eventListeners) {
            eventListeners.forEach(callback => callback(data));
        }
    }
}

export const searchResultSelectEventEmitter = new EventEmitter<ISearchResultItem>();
export const routeFinderItemClickedEventEmitter = new EventEmitter<{ routeId: number }>();
export const mapPanToSearchLocationEventEmitter = new EventEmitter<{ latLng: L.LatLng }>();
export const routeFinderEventEmitter = new EventEmitter<{ open: boolean }>();
export const drawRouteDetailsEventEmitter = new EventEmitter<{ open: boolean }>();
export const addImageEventEmitter = new EventEmitter<{ open: boolean }>();

export const EventNames = {
    moveToSearchLocation: 'moveToSearchLocation',
    flyToSearchResult: 'flyToSearchResult',
    flyToRoute: 'flyToRoute',
    toggleRouteFinder: 'toggleRouteFinder',
    toggleDrawRouteDetails: 'toggleDrawRouteDetails',
    toggleAddImage: 'toggleAddImage',
}