import { ApiResult, IApiResult } from "../models/api/apiResult";
import { IUserModel } from "../models/general/User";
import { OauthVerificationRequest } from "../models/loginModels/LoginModels";
import { getUserTokenCookie, handleApiResultToken } from "./clientHelpers";

const verifyOathUrl = 'account/verify-oauth'
const getUserDataUrl = 'account/get-user'

export const getUserData = async (): Promise<IApiResult<IUserModel>> => {
    try {

        const response = await fetch(process.env.REACT_APP_BASE_URL + getUserDataUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },
        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<IUserModel> = json as IApiResult<IUserModel>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<IUserModel>(false);
    }
}

export const verifyOath = async (data: OauthVerificationRequest): Promise<IApiResult<IUserModel>> => {
    try {

        const response = await fetch(process.env.REACT_APP_BASE_URL + verifyOathUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json', // Set appropriate content type
            },
        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<IUserModel> = json as IApiResult<IUserModel>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<IUserModel>(false);
    }
}

