import { Row, Col, Divider } from "antd"
import Title from "antd/es/typography/Title"
import { useEffect } from "react"
import '../css/pages.css'
import ReactGA from 'react-ga4'

export function Privacy() {
    ReactGA.send({ hitType: "pageview", page: "/privacy", title: "Privacy" });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return <>
        <div className="container">
            <Title>Privacy</Title>
            <Divider />
            <Row>
                <Col span={24}>
                    <h1>Privacy Policy</h1>

                    <p>At off-road-map.uk, we understand the importance of your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>

                    <h2>Information We Collect</h2>
                    <p>When you register to use our website, we may collect certain personal information from you, including your name and email address. We collect this information to create and manage your account, provide you with access to our services, and communicate with you regarding your account and our services.</p>

                    <h2>How We Use Your Information</h2>
                    <p>We may use the information we collect from you to:</p>
                    <ul>
                        <li>Create and manage your account.</li>
                        <li>Provide you with access to our services and features.</li>
                        <li>Communicate with you regarding your account, updates, and important information.</li>
                        <li>Respond to your inquiries, questions, and requests.</li>
                    </ul>

                    <h2>Disclosure of Your Information</h2>
                    <p>We may disclose your personal information to third parties only in the following circumstances:</p>
                    <ul>
                        <li>With your consent.</li>
                        <li>To comply with legal obligations or enforce our policies.</li>
                        <li>To protect the rights, property, or safety of our website, users, or others.</li>
                    </ul>

                    <h2>Data Security</h2>
                    <p>We have implemented appropriate technical and organizational measures to safeguard the information we collect from unauthorized access, use, disclosure, alteration, or destruction.</p>

                    <h2>Your Rights</h2>
                    <p>You have the right to access, update, correct, or delete your personal information. You may also request that we restrict the processing of your information or object to the processing of your information under certain circumstances. To exercise your rights, please contact us using the contact information provided below.</p>

                    <h2>Updates to this Privacy Policy</h2>
                    <p>We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on this page.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our Privacy Policy or our practices regarding your personal information, please contact us at <a href="mailto:offroadmapuk@gmail.com">offroadmapuk@gmail.com</a>.</p>

                    <p>Last Updated: April 25th 2024</p>
                </Col>
            </Row>
        </div>

    </>
}

export default Privacy