import { RcFile } from "antd/es/upload";

export class FileHelpers {

    static imageResizeKb = 500;
    static maxImageSideLength = 800;

    // Function to resize the image based on file size
    static resizeImage(file: RcFile, sizeKb: number, quality: number = 1, callback: (resizedFile: RcFile | null) => void) {
        const reader = new FileReader();

        reader.onload = function (event: ProgressEvent<FileReader>) {
            const image = new Image();
            image.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = image.width * quality;
                canvas.height = image.height * quality;
                ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);


                canvas.toBlob(function (blob) {
                    if (blob && blob.size <= sizeKb * 1024) { // sizeKb limit
                        const resizedFile = new File([blob], file.name, { type: file.type });
                        callback(resizedFile as RcFile);
                    } else {
                        // Retry resizing with lower quality
                        if (quality > 0.1) {
                            const resizedFile = new File([blob as Blob], file.name, { type: file.type });
                            FileHelpers.resizeImage(resizedFile as RcFile, sizeKb, quality - 0.1, callback);
                        } else {
                            console.log("File size still exceeds the limit even with lowest quality.");
                            callback(null);
                        }
                    }
                }, file.type);
            };

            if (event.target) {
                image.src = event.target.result as string;
            }
        };

        reader.readAsDataURL(file);
    }
}