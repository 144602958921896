import { RcFile } from "antd/es/upload"
import { LatLng } from "leaflet"

export interface IMapImage {
    position: L.LatLng | null
    file: RcFile | null
    routeId: number | null
    createdDate: Date | null
}

export class MapImage implements IMapImage {
    constructor(pos: LatLng) {
        this.position = pos
        this.file = null
        this.routeId = null
        this.createdDate = null;
    }
    position: LatLng;
    file: RcFile | null
    routeId: number | null;
    createdDate: Date | null;
}