import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { App, Button, Col, Divider, Drawer, Row, Space, Spin } from 'antd';
import { MapActions } from './components/map/helpers/MapActions';
import { useAppContext } from './contexts/AppContext';
import { useMapContext } from './contexts/MapContext';
import { getAllPolylines } from './client/mapclient';
import { getUserData } from './client/accountClient';
import { useOnMountUnsafe } from './hooks/useOnMountUnsafe';
import './css/app.css'
import SiteMenu from './components/general/SiteMenu';
import { Icons } from './components/map/ui/Icons';
import { MapLayer } from './components/map/helpers/MapLayer';
import { routeFinderItemClickedEventEmitter, EventNames } from './components/map/helpers/EventEmitter';
import ReactGA from 'react-ga4'

const Layout = () => {

    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext();
    const app = App.useApp()
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate();

    useOnMountUnsafe(() => {
        const fetchAppData = async () => {

            updateAppState({
                ...appState,
                isLoading: true
            })
            const mapDataResponse = await getAllPolylines();
            const userDataResponse = await getUserData();

            if (userDataResponse.isSuccess && userDataResponse.data) {
                appState.account = userDataResponse.data
            }

            if (mapDataResponse.isSuccess && mapDataResponse.data) {
                const routeId = new URLSearchParams(window.location.search).get('routeId');
                const mapConfig = MapLayer.getMapOptions()[1] // OS Map
                MapLayer.UpdateMapLayerLimits(mapConfig);
                updateMapState({
                    ...mapState,
                    selectedMap: mapConfig,
                    polylines: mapDataResponse.data,
                    clickedPolylineId: Number(routeId)
                });
                if (routeId) {
                    setTimeout(() => {
                        routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: Number(routeId) })
                    }, 500)
                }

            }
            updateAppState({
                ...appState,
                isLoading: false
            })
        }

        fetchAppData();
    })

    useEffect(() => {
    }, [appState])


    MapActions.init({
        app: app,
        appState: appState,
        updateAppSate: updateAppState,
        mapState: mapState,
        updateMapState: updateMapState,
    });

    const onNavigate = (page: string) => {
        navigate(page, { replace: true })
        setMenuOpen(false)
    }


    const menuBtnStyle: React.CSSProperties = {
        position: 'fixed',
        top: '15px',
        right: '15px',
        zIndex: 1002,
        boxShadow: 'grey 0px 0px 15px 1px',
        width: '50px',
        height: '50px',
        textTransform: 'uppercase'
    }

    const toggleMenu = (open: boolean) => {
        ReactGA.event({
            category: 'Toggle',
            action: 'Side Menu',
            label: open ? 'Open' : 'Closed'
        });
        setMenuOpen(open)
    }

    return (
        <>
            <Spin spinning={appState.isLoading} fullscreen style={{ zIndex: 9999 }}
                indicator={<img className='loading-logo' src={`${process.env.REACT_APP_BASE_URL}dist/images/logo.png`}></img>}
            />

            <Outlet />

            <Button
                style={menuBtnStyle}
                onClick={() => toggleMenu(true)}
                size='large'
                icon={Icons.BurgerMenuIcon}
                type='primary'>
            </Button>

            <Drawer
                title={appState.account.isLoggedIn ? `Hello again` : 'Menu'}
                placement='right'
                open={menuOpen}
                destroyOnClose
                onClose={() => toggleMenu(false)}
                styles={{
                    body: {
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}>
                <SiteMenu onNavigate={onNavigate} closeMenu={() => toggleMenu(false)} />
            </Drawer>
        </>
    )
};

export default Layout;