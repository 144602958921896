import { Polyline, useMap } from "react-leaflet"
import { ISearchResultItem } from "../ui/SearchBox";
import { useMapContext } from "../../../contexts/MapContext";
import { useEffect } from "react";
import { EventNames, mapPanToSearchLocationEventEmitter, routeFinderItemClickedEventEmitter, searchResultSelectEventEmitter } from './EventEmitter';
import { LatLng, LatLngBounds } from "leaflet";
import { MapHelpers } from "./MapHelpers";
import { eventNames } from "process";
import { useWindowContext } from "../../../contexts/WindowContext";

const MapUiEventHandler = () => {
    const map = useMap();
    const { isMobile } = useWindowContext();

    const { mapState } = useMapContext();

    const setUpFlyToSearchResult = () => {
        const flyToSearchResult = (searchResult: ISearchResultItem) => {
            map.flyTo(searchResult.position, mapState.selectedMap.crsOptions.zoom);
        };

        searchResultSelectEventEmitter.off(EventNames.flyToSearchResult, flyToSearchResult);
        searchResultSelectEventEmitter.on(EventNames.flyToSearchResult, flyToSearchResult);
    }

    const setUpMoveToSearchLocation = () => {
        const moveToSearchLocation = (p: { latLng: L.LatLng }) => {
            map.panTo(p.latLng);
        };

        mapPanToSearchLocationEventEmitter.off(EventNames.moveToSearchLocation, moveToSearchLocation);
        mapPanToSearchLocationEventEmitter.on(EventNames.moveToSearchLocation, moveToSearchLocation);
    }

    const setUpFlyToClickedRoute = () => {
        const flyToRoute = (item: { routeId: number }) => {

            const route = mapState.polylines.find(i => i.id === item.routeId);

            if (route) {
                let bounds = MapHelpers.getBounds(route.points, isMobile);
                map.flyToBounds(bounds.pad(0.1));
            }
        };

        routeFinderItemClickedEventEmitter.off(EventNames.flyToRoute, flyToRoute);
        routeFinderItemClickedEventEmitter.on(EventNames.flyToRoute, flyToRoute);
    }


    useEffect(() => {
        setUpFlyToSearchResult();
        setUpFlyToClickedRoute();
        setUpMoveToSearchLocation();
    }, [mapState])


    return <></>
}

export default MapUiEventHandler