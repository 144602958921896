import { useMapEvents } from "react-leaflet"
import { themeStyles } from "../../../constants/theme"
import { Button } from "antd"
import { Icons } from "./Icons"
import { useMapContext } from "../../../contexts/MapContext"
import ReactGA from 'react-ga4'

export const MyLocationBtn = () => {
    const { mapState } = useMapContext();

    const map = useMapEvents({
        locationfound(e) {
            map.flyTo(e.latlng, mapState.selectedMap.crsOptions.maxZoom - 1)
        },
    })

    const onClick = () => {
        map.locate()
        ReactGA.event({
            category: 'Map Interaction',
            action: 'Click',
            label: 'My Location'
        });
    }

    const style: React.CSSProperties = {
        position: 'fixed',
        left: '15px',
        top: '15px',
        zIndex: 1002,
        fontSize: '25px',
        backgroundColor: 'white',
        color: themeStyles.colorPrimary,
        boxShadow: '0 0 15px 1px grey',
        height: '40px',
        width: '40px'
    }

    return <Button
        shape="default"
        style={style}
        size="large"
        icon={Icons.LocationCrosshairs}
        onClick={onClick} />
}

export default MyLocationBtn