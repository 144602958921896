import { Space, Button, ConfigProvider } from "antd";
import { MapActions } from "../helpers/MapActions";
import { Icons } from "../ui/Icons";
import { useMapContext } from "../../../contexts/MapContext";
import { LatLng } from "leaflet";
import { themeStyles } from "../../../constants/theme";

export const AddImageContextMenu = () => {

    const { mapState, updateMapState } = useMapContext();

    const updateImageLocation = () => {

        if (!mapState.addingImage?.position)
            return;

        updateMapState({
            ...mapState,
            addingImage: {
                ...mapState.addingImage,
                position: mapState.contextMenuPos as LatLng,
                routeId: 0
            },
            showContextMenu: false
        })
    }

    return <Space.Compact direction={'vertical'}>
        <Button
            icon={Icons.AddImageIcon}
            onClick={(e) => { e.stopPropagation(); updateImageLocation() }}
            size="middle"
            type="default">
            Set Photo Location
        </Button>
        <ConfigProvider theme={{
            token: {
                colorPrimary: themeStyles.colorError,
            }
        }}>
            <Button
                icon={Icons.CancelIcon}
                onClick={(e) => { e.stopPropagation(); MapActions.cancelAddImageMode() }}
                size="middle"
                type="primary">
                Cancel
            </Button>
        </ConfigProvider>

    </Space.Compact>
}

export default AddImageContextMenu