import { Divider, Row, Col, Collapse, CollapseProps } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import '../css/pages.css'
import ReactGA from 'react-ga4'

function HelpPage() {
    ReactGA.send({ hitType: "pageview", page: "/help", title: "Help" });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const helpItems: { label: string, children: React.ReactNode }[] = [
        {
            label: 'Something is wrong on the map',
            children: <>
                <p>
                    If you have found a problem with anything on the map, please contact us
                    at <a href="mailto:offroadmapuk@gmail.com">offroadmapuk@gmail.com</a> to
                    let us know.
                </p>
                <p>
                    Thank you!
                </p>
            </>
        },
        {
            label: 'Why was my route rejected?',
            children: <>
                <p>
                    Routes should generally stick to 'off-road' locations, and be a realistic
                    representation of something that others can follow.
                </p>
                <p>
                    If large chunks of the route are along a main road, or appear to be going somewhere
                    that is obviously inaccessible or not well defined, it may be rejected.
                </p>
            </>
        },
        {
            label: 'Why was my photo rejected?',
            children: <>
                <p>
                    An image will be rejected if it does not provide enough
                    insight into what the route is like, or if there are too many images for that location already.
                </p>
            </>
        },
        {
            label: 'My route is not aligned with where I drew it on the map',
            children: <>
                <p>
                    This can happen when adding routes using a mobile phone screen.
                </p>
                <p>
                    Don't worry, we see all submitted routes before they go live, and will correct this issue for you.
                </p>
            </>,
        },
        {
            label: "Why can't I delete something after it has been published?",
            children: <>
                <p>
                    At off-road-map.uk, all contributions undergo thorough review before being published.
                    Once an item is published, it is considered a valuable addition to our resource,
                    available for others to utilise. To maintain the integrity of our platform and prevent
                    unintended or malicious removal of these resources, contributions cannot be deleted after publication.
                </p>
                <p>
                    If you would like to have an item considered for removal, please contact
                    us at <a href="mailto:offroadmapuk@gmail.com">offroadmapuk@gmail.com</a>
                </p>
            </>
        },
    ]

    const items: CollapseProps['items'] = helpItems.map((item, i) => {
        return {
            key: `${i}`,
            label: item.label,
            children: item.children
        }
    })

    return <>
        <div className="container">
            <Title>Help</Title>
            <Divider />
            <Row>
                <Col span={24}>
                    <Collapse items={items} defaultActiveKey={['']} />
                </Col>
            </Row>
        </div>
    </>
}

export default HelpPage