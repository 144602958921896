import { useEffect, useState } from "react";
import { App, Image, Button, Table, TableColumnsType, Tag, Tooltip } from "antd";
import { themeStyles } from "../../constants/theme";
import { useAppContext } from "../../contexts/AppContext";
import { useMapContext } from "../../contexts/MapContext";
import { PolylineImage } from "../../models/map/PolylineImage";
import { IPolylineModel } from "../../models/map/PolylineModel";
import { Icons } from "../map/ui/Icons";
import { UserType } from "../../models/general/User";
import { useWindowContext } from "../../contexts/WindowContext";
import { approveImage, deleteImage } from "../../client/imageClient";


interface IMyImagesItem {
    key: string,
    image: PolylineImage,
    route: IPolylineModel
}

export const SubmissionsImages = () => {
    const [myItems, setMyItems] = useState<IMyImagesItem[]>([])

    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext();
    const app = App.useApp();
    const { isMobile } = useWindowContext();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const account = appState.account;
        let items: IMyImagesItem[] = []
        if (account.userType === UserType.Public) {
            const accountImages = account.images;
            // Get all images belonging to user
            accountImages.forEach(img => {
                let polyline = mapState.polylines.find(p => img.routeId === p.id)
                if (polyline) {
                    items.push({
                        key: `${img.id}`,
                        image: img,
                        route: polyline
                    })
                }
            })
        }
        else {
            mapState.polylines.forEach(p => {
                // For admin, get all images
                p.images.forEach(img => {
                    items.push({
                        key: `${img.id}`,
                        image: img,
                        route: p
                    })
                })
            })
        }
        items = items.sort((a, b) => {
            if (a.image.pending !== b.image.pending) {
                return a.image.pending ? -1 : 1;
            } else {
                return new Date(a.image.createdDate).getTime() - new Date(b.image.createdDate).getTime();
            }
        })
        setMyItems(items);
    }, [mapState, appState])

    const removeImageFromAccount = (id: number) => {
        let userImages = appState.account.images.filter(i => i.id !== id)
        updateAppState({
            ...appState,
            account: {
                ...appState.account,
                images: userImages
            }
        })
    }

    const removeImageFromMap = (img: PolylineImage) => {

        let polylines = [...mapState.polylines]
        let p = polylines.filter(p => p.id === img.routeId)[0];
        let index = polylines.indexOf(p);
        p.images = p.images.filter(i => i.id !== img.id);
        polylines.splice(index, 0, p);

        updateMapState({
            ...mapState,
            polylines: polylines
        })
    }

    const submitDeleteImage = async (img: PolylineImage) => {
        setLoading(true);
        const result = await deleteImage(img.id);
        if (result.isSuccess) {
            removeImageFromAccount(img.id);
            removeImageFromMap(img);
            app.notification.info({
                message: 'Image Deleted',
                placement: 'top'
            })
        }
        else {
            app.notification.error({
                message: 'Deleting Failed',
                placement: 'top'
            })
        }
        setLoading(false)
    }

    const onDeleteImageClicked = (img: PolylineImage): void => {

        app.modal.confirm({
            title: 'Delete image',
            content: 'Are you sure?',
            centered: true,
            cancelText: 'Cancel',
            closeIcon: null,
            maskClosable: true,
            okButtonProps: {
                style: {
                    backgroundColor: themeStyles.colorWarn
                }
            },
            okText: 'Confirm',
            onOk: (close) => {
                submitDeleteImage(img)
                close();
            }
        })
    }

    const shouldDisableDeleteBtn = (pending: boolean) => {
        if (appState.account.userType === UserType.Admin) {
            return false;
        }
        if (!pending) {
            return true;
        }
    }

    const onApproveImageClicked = async (image: PolylineImage) => {
        setLoading(true);
        const result = await approveImage(image.id);
        if (result.isSuccess) {
            let polylines = [...mapState.polylines];
            let p = polylines.find(x => x.id === image.routeId)
            if (p) {
                let index = polylines.indexOf(p)
                let newImage = p.images.find(x => x.id === image.id)
                if (newImage) {
                    newImage.pending = false;
                    let newImages = p.images.filter(x => x.id !== image.id)
                    newImages.push(newImage);
                    p.images = newImages;
                    polylines.splice(index, 0, p);
                    updateMapState({
                        ...mapState,
                        polylines: polylines
                    })
                    app.notification.info({
                        message: 'Image Approved',
                        placement: 'top'
                    })
                }
            }
        }
        else {
            app.notification.error({
                message: 'Approval Failed',
                placement: 'top'
            })
        }
        setLoading(false)
    }

    const tableColumns: TableColumnsType<IMyImagesItem> = [
        {
            title: 'Image',
            key: 'key',
            width: '100px',
            render: (item: IMyImagesItem) => {
                return <>
                    <div style={{
                        height: 80,
                        width: 80,
                        textAlign: 'center'
                    }}>
                        <Image
                            height={'100%'}
                            width={'100%'}
                            style={{ objectFit: 'cover', zIndex: 1004 }}
                            src={`${process.env.REACT_APP_BASE_URL}${item.image.filePath}`} />
                    </div>
                </>
            }
        },
        {
            title: 'Route',
            key: 'route',
            render: (item: IMyImagesItem) => item.route.name
        },
        {
            title: 'Status',
            key: 'pending',
            render: (i: IMyImagesItem) => <>
                {
                    i.image.pending
                        ? <Tooltip title={isMobile ? 'Pending' : ''}>
                            <Tag color={themeStyles.colorPrimary}>{isMobile ? Icons.PendingIcon : 'Pending'}</Tag>
                        </Tooltip>
                        : <Tooltip title={isMobile ? 'Published' : ''}>
                            <Tag color={themeStyles.colorSuccess}>{isMobile ? Icons.CheckMarkIcon : 'Published'}</Tag>
                        </Tooltip>
                }
            </>
        },
        {
            title: '',
            key: 'delete',
            align: 'center',
            render: (item: IMyImagesItem) =>
                <Tooltip title={item.image.pending || appState.account.userType === UserType.Admin ? 'Delete' : 'Cannot delete published images'}>
                    <Button
                        type="default"
                        color="red"
                        disabled={shouldDisableDeleteBtn(item.image.pending)}
                        icon={Icons.DeleteIcon}
                        onClick={() => onDeleteImageClicked(item.image)}
                    />
                </Tooltip>
        },
        {
            title: '',
            key: 'approve',
            align: 'center',
            hidden: appState.account.userType !== UserType.Admin,
            render: (item: IMyImagesItem) => appState.account.userType === UserType.Admin &&
                <Button
                    disabled={!item.image.pending}
                    type="primary"
                    icon={Icons.CheckMarkIcon}
                    onClick={() => onApproveImageClicked(item.image)}
                />
        }
    ]

    return <Table
        loading={loading}
        pagination={{ pageSize: 5 }}
        bordered size="small"
        dataSource={myItems}
        columns={tableColumns} />
}

export default SubmissionsImages


