import { Divider, Row, Col, App, Button } from "antd"
import { useAppContext } from "../../contexts/AppContext"
import { UserModel, UserType } from "../../models/general/User"
import { setCookie, userTokenCookieName } from "../../client/clientHelpers"
import Login from "./Login"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { themeStyles } from "../../constants/theme"
import { MapActions } from "../map/helpers/MapActions"
import { routeFinderEventEmitter, EventNames } from "../map/helpers/EventEmitter"
import ReactGA from 'react-ga4'

interface IProps {
    onNavigate: (page: string) => void
    closeMenu: () => void
}
export const SiteMenu = (props: IProps) => {

    const { appState, updateAppState } = useAppContext()
    const app = App.useApp()
    const [footerImgIndex, setFooterImgIndex] = useState(0);
    const location = useLocation();

    useEffect(() => {
        setFooterImgIndex(Math.floor(Math.random() * 3));
    }, [])

    const onlogout = () => {
        setCookie(userTokenCookieName, '', 0);
        updateAppState({
            ...appState,
            account: new UserModel()
        })
        props.onNavigate('/')
        app.notification.info({
            placement: 'top',
            message: 'Session Cleared'
        })
        MapActions.onLogout();
    }

    const onLoginComplete = () => {
        props.closeMenu()
    }

    const menuBtnStyle: React.CSSProperties = {
        width: '90%',
        margin: '10px 5%'
    }

    const footerImgStyle: React.CSSProperties = {
        height: '70px',
        width: 'auto',
        margin: 'auto',
    }

    const footerImages: JSX.Element[] = [
        <img style={footerImgStyle} src={`${process.env.REACT_APP_BASE_URL}dist/images/three-row-2.png`} />,
        <img style={footerImgStyle} src={`${process.env.REACT_APP_BASE_URL}dist/images/three-row-3.png`} />,
        <img style={footerImgStyle} src={`${process.env.REACT_APP_BASE_URL}dist/images/three-row-1.png`} />,
    ]



    let menuItems: { label: string, route: string, hidden: boolean, onClick?: () => void }[] = [
        { label: 'Map', route: '/map', hidden: false },
        { label: 'Route Finder', route: '/map?q=route-finder', hidden: false, onClick: () => routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: true }) },
        { label: appState.account.userType === UserType.Public ? 'My Submissions' : 'Submissions', route: '/my-submissions', hidden: !appState.account.isLoggedIn },
        { label: 'About', route: '/about', hidden: false },
        { label: 'Help', route: '/help', hidden: false },
    ]

    const isActive = (route: string) => {
        return location.pathname.indexOf(route) > -1
    }

    return <>
        <div className='full-width' style={{ textAlign: 'center' }} >
            <img className='menu-logo' src={`${process.env.REACT_APP_BASE_URL}dist/images/logo.png`}></img>
        </div>
        <div style={{ textAlign: 'center' }}>
            Off-Road Map UK
        </div>
        <Divider />
        {
            menuItems.filter(x => !x.hidden).map((x, i) =>
                <Row key={`side-menu-item-${i}`} align={"middle"} justify={"center"}>
                    <Col span={24}>
                        <Button
                            style={{
                                ...menuBtnStyle,
                                borderColor: isActive(x.route) ? themeStyles.colorPrimary : '#d9d9d9'
                            }}
                            size="large"
                            type="default"
                            onClick={() => {
                                x.onClick?.();
                                props.onNavigate(x.route)
                                ReactGA.event({
                                    category: 'Site Menu',
                                    action: 'Click',
                                    label: x.label
                                });
                            }}>{x.label}</Button>
                    </Col>
                </Row>)
        }
        {
            appState.account.isLoggedIn
                ? <Row align={"middle"} justify={"center"}>
                    <Col span={24}>
                        <Button style={menuBtnStyle} size="large" type="default" onClick={onlogout}>Logout</Button>
                    </Col>
                </Row>
                : <Login onLoginComplete={onLoginComplete} buttonStyle={menuBtnStyle} />
        }
        <Divider />
        <Row align={"bottom"} style={{ textAlign: 'center', flex: 1 }}>
            <Col span={24}>
                <div>
                    {footerImages[footerImgIndex]}
                </div>
                <div style={{ color: 'lightgray', marginTop: '4px' }}>
                    off-road-map.uk {new Date().getFullYear()}
                </div>
            </Col>
        </Row>
    </>
}

export default SiteMenu