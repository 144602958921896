import '@flaticon/flaticon-uicons/css/regular/straight.css'
import { PolylineType } from "../../../models/map/PolylineModel";

export class Icons {
    static DrawRouteModeIcon = <i className="fi fi-rs-route"></i>
    static AddImageIcon = <i className="fi fi-rs-picture"></i>
    static HelpIcon = <i className="fi fi-rs-interrogation"></i>
    static UndoIcon = <i className="fi fi-rs-undo-alt"></i>
    static CancelIcon = <i className="fi fi-rs-circle-xmark"></i>
    static FinishDrawRouteIcon = <i className="fi fi-rs-disk"></i>
    static OffRoadIcon = <i className="fi fi-rs-truck-monster"></i>
    static DirtBikeIcon = <i className="fi fi-rs-motorcycle"></i>
    static MountainBikeIcon = <i className="fi fi-rs-biking-mountain"></i>
    static HikingIcon = <i className="fi fi-rs-walking"></i>
    static SearchIcon = <i className="fi fi-rs-search"></i>
    static RouteFinderIcon = <i className="fi fi-rs-search-location"></i>
    static LocationCrosshairs = <i className="fi fi-rs-location-crosshairs"></i>
    static LocateRouteCrosshairs = <i className="fi fi-rs-target"></i>
    static CheckMarkIcon = <i className="fi fi-rs-checkbox"></i>
    static DetailsIcon = <i className="fi fi-rs-info"></i>
    static BurgerMenuIcon = <i className="fi fi-rs-list"></i>
    static MapLayersIcon = <i className="fi fi-rs-layers"></i>
    static DeleteIcon = <i className="fi fi-rs-trash"></i>
    static PendingIcon = <i className="fi fi-rs-hourglass-end"></i>
    static MarkerIcon = <i className="fi fi-rs-marker"></i>
    static CircleIcon = <i className="fi fi-rs-circle"></i>

    static getRouteTypeIcon(type: PolylineType) {
        switch (type) {
            case PolylineType.OffRoad: return Icons.OffRoadIcon;
            case PolylineType.Hiking: return Icons.HikingIcon;
            case PolylineType.MountainBike: return Icons.MountainBikeIcon;
            case PolylineType.DirtBike: return Icons.DirtBikeIcon;
            default: return <></>;
        }
    }
}

