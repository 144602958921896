import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { IMapState, MapState } from '../models/map/MapState';
import { getAllPolylines } from '../client/mapclient';
import { useAppContext } from './AppContext';

// Create a context object
const MapContext = createContext<{ mapState: IMapState; updateMapState: (newState: IMapState) => void }>({
    mapState: new MapState(),
    updateMapState: () => { }
});

// Create a provider component
export const MapContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [mapState, setMapState] = useState<IMapState>(new MapState());

    const updateMapState = (newState: IMapState) => {
        setMapState(newState);
    };

    return (
        <MapContext.Provider value={{ mapState, updateMapState }}>
            {children}
        </MapContext.Provider>
    );
}

// Custom hook to consume the context
export const useMapContext = () => useContext(MapContext);