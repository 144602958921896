import { Polyline, Tooltip } from "react-leaflet";
import { IPolylineModel } from "../../../models/map/PolylineModel"
import { MapHelpers } from "../helpers/MapHelpers"
import { useMapContext } from "../../../contexts/MapContext";

interface IProps {
    id: number
}
export const ClickedPolyline = (props: IProps) => {
    const { mapState } = useMapContext();

    const p = mapState.polylines.find(x => x.id === props.id)
    if (!p) {
        return <></>
    }

    const options = MapHelpers.getPolylinePathOptions(p);

    return <Polyline
        pathOptions={options}
        className='animated-polyline'
        positions={p.points} >
        <Tooltip sticky>
            {p.name}
        </Tooltip>
    </Polyline>
}

export default ClickedPolyline