import { Button, Space } from "antd";
import { ISearchResultItem } from "./SearchBox";
import { useMapContext } from "../../../contexts/MapContext";
import { useEffect, useState } from "react";
import { EventNames, searchResultSelectEventEmitter } from "../helpers/EventEmitter";
import { useWindowContext } from "../../../contexts/WindowContext";
import '../../../css/searchResults.css'
import { SortOption } from "../../../models/map/RouteFilter";
import ReactGA from 'react-ga4'

export const SearchResults = () => {

    const { mapState, updateMapState } = useMapContext();
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const { isMobile } = useWindowContext();

    useEffect(() => {
        setSelectedItemIndex(-1)
    }, [mapState])

    const getRegion = (item: ISearchResultItem) => {
        if (item.districtBorough)
            return <>, {item.districtBorough}</>;
        if (item.county)
            return <>, {item.county}</>;

        return <></>
    }

    const getPopulatedPlace = (item: ISearchResultItem) => {
        if (item.populatedPlace)
            return <>, {item.populatedPlace}</>
    }

    const onItemClicked = (e: React.MouseEvent<HTMLElement, MouseEvent>, item: ISearchResultItem, i: number): void => {
        e.stopPropagation()
        setSelectedItemIndex(i)
        updateMapState({
            ...mapState,
            showContextMenu: false,
            searchResults: [],
            filter: {
                ...mapState.filter,
                searchPoint: item.position,
                sort: SortOption.SelectedLocation
            }
        });
        searchResultSelectEventEmitter.emit(EventNames.flyToSearchResult, item)
        ReactGA.event({
            category: 'Search Results',
            action: 'Click',
            label: item.name
        });
    }

    const getItemStyle = (index: number): React.CSSProperties => {
        return {
            border: selectedItemIndex === index ? 'solid 1px blue' : 'solid 1px #eaeaea'
        }
    }

    const onCloseResults = () => {
        updateMapState({
            ...mapState,
            searchResults: []
        })
    }
    const containerStyle: React.CSSProperties = {
        position: 'fixed',
        right: '50%',
        transform: 'translateX(50%)',
        width: '450px',
        maxWidth: 'calc(100vw - 40px)',
        bottom: '90px',
        zIndex: 1001,
        backgroundColor: 'white',
        padding: '10px',
        boxShadow: '0 0 15px 1px grey'
    }
    const closeBtnAreaStyle: React.CSSProperties = {
        zIndex: 1001,
    }

    const resultsScrollContainerStyle: React.CSSProperties = {
        maxHeight: isMobile ? '200px' : '300px',
        overflow: 'auto',
        overflowX: 'hidden',
        width: '100%'
    }

    const resultContainerStyle: React.CSSProperties = {
        borderBottom: 'solid 1px #eaeaea',
        padding: '1px 5px'
    }
    const resultTitleStyle: React.CSSProperties = {
        fontSize: '15px'
    }
    const resultSubtitleStyle: React.CSSProperties = {
        color: 'grey',
        fontSize: '12px'
    }

    if (mapState.searchResults.length === 0)
        return <></>


    return <div style={containerStyle}>
        <Space direction="vertical" className="full-width" style={closeBtnAreaStyle}>
            {
                mapState.searchResults.length > 0
                && <Button type="primary"
                    onClick={onCloseResults}>
                    Close
                </Button>
            }
            <Space.Compact
                direction="vertical"
                style={resultsScrollContainerStyle}>

                {
                    mapState.searchResults.map((res, i) => {
                        return <div
                            key={`search-results-${i}`}
                            style={getItemStyle(i)}
                            onClick={(e) => onItemClicked(e, res, i)}>
                            <div style={resultContainerStyle} className="search-result-hover">
                                <div style={resultTitleStyle}>
                                    {res.name}{getPopulatedPlace(res)}{getRegion(res)}
                                </div>
                                <div style={resultSubtitleStyle}>
                                    <span>({res.localType}) </span>
                                    <span>{res.region}, {res.country}</span>
                                </div>
                            </div>
                        </div>
                    })
                }
            </Space.Compact>
        </Space>
    </div>
}

export default SearchResults