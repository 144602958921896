import { IPolylineModel } from "./PolylineModel";
import { IMarkerModel } from "./MarkerModel";
import { ISearchResultItem } from "../../components/map/ui/SearchBox";
import { IRouteFilter, RouteFilter } from "./RouteFilter";
import { IMapImage } from "./MapImage";
import { IMapLayerConfig, MapLayer } from "../../components/map/helpers/MapLayer";


export enum MapInteractionMode {
    Normal,
    DrawRoute,
    AddImage
}

export interface IMapState {

    interactionMode: MapInteractionMode
    polylines: IPolylineModel[]
    editingPolyline: IPolylineModel | null
    markers: IMarkerModel[]
    showContextMenu: boolean
    contextMenuPos: L.LatLng | null
    /**An inert circle marker placed for basic map click feedback on normal mode */
    clickCirclePos: L.LatLng | undefined | null
    isEditMode: boolean
    searchResults: ISearchResultItem[]
    filter: IRouteFilter
    addingImage: IMapImage | null,
    clickedPolylineId: number
    selectedMap: IMapLayerConfig
}


export class MapState implements IMapState {

    polylines = []
    editingPolyline: IPolylineModel | null = null
    markers = []
    clickCirclePos: L.LatLng | undefined | null
    interactionMode: MapInteractionMode = MapInteractionMode.Normal;
    showContextMenu = false;
    contextMenuPos: L.LatLng | null = null;
    isEditMode: boolean = false
    searchResults: ISearchResultItem[] = []
    filter: IRouteFilter = new RouteFilter()
    addingImage: IMapImage | null = null
    clickedPolylineId: number = 0
    selectedMap: IMapLayerConfig = MapLayer.getMapOptions()[1]
}
