import NormalmodeMapControls from "./NormalmodeMapControls"
import { mobileBreakPoint, useWindowContext } from "../../../contexts/WindowContext"


const MapControls = () => {

    const { isMobile } = useWindowContext();

    const mapControlsContainerStyle: React.CSSProperties = {
        position: 'fixed',
        bottom: '25px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1001,
        backgroundColor: 'white',
        border: 'solid 1px grey',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        padding: isMobile ? '5px' : '10px',
        width: isMobile ? 'calc(100vw - 30px)' : `${mobileBreakPoint - 30}px`,
        minWidth: '300px',
        overflow: 'hidden',
        borderRadius: '5px'
    }



    return <div style={mapControlsContainerStyle}>
        <NormalmodeMapControls />
    </div>

}

export default MapControls;