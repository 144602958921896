import { Drawer, Space, Button, ConfigProvider, Col, Row, UploadFile, Upload, Empty, Result, Alert, App, Spin, Select, Divider } from "antd"
import { themeStyles } from "../../../constants/theme"
import { IPolylineModel } from "../../../models/map/PolylineModel"
import { MapActions } from "../helpers/MapActions"
import { MapHelpers } from "../helpers/MapHelpers"
import { Icons } from "./Icons"
import { useMapContext } from "../../../contexts/MapContext"
import React, { useEffect, useRef, useState } from "react"
import { mobileBreakPoint, useWindowContext } from "../../../contexts/WindowContext"
import { addImageEventEmitter, EventNames } from "../helpers/EventEmitter"
import { UiHelpers } from "../../../helpers/UiHelpers"
import { RcFile } from "antd/es/upload"
import RoutesList, { ISearchResultTableItem } from "./RoutesList"
import { LatLng } from "leaflet"
import { IMapImage } from "../../../models/map/MapImage"
import { FileHelpers } from "../../../helpers/FileHelpers"
import { useAppContext } from "../../../contexts/AppContext"
import Login from "../../general/Login"
import { MapLayer } from "../helpers/MapLayer"
import { DateHelpers } from "../../../helpers/DateHelpers"
import ReactGA from 'react-ga4'

export const AddImage = () => {
    const [open, setOpen] = useState(false);
    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext();
    const { isMobile } = useWindowContext();
    const [images, updateImages] = useState<UploadFile[]>([])
    const uploadRef = useRef<HTMLDivElement>(null);
    const [routes, updateRoutes] = useState<IPolylineModel[]>([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [imgMonth, setImgMonth] = useState<number | null>(null);
    const [imgYear, setImgYear] = useState<number | null>(null);
    const app = App.useApp()

    const setUpToggleAddImage = () => {
        const toggleAddImage = (data: { open: boolean }) => {
            if (!data.open) {
                updateImages([]);
            }
            setOpen(data.open)
        };

        addImageEventEmitter.off(EventNames.toggleAddImage, toggleAddImage);
        addImageEventEmitter.on(EventNames.toggleAddImage, toggleAddImage);
    }


    const setRoutesNearImage = () => {
        if (!mapState.addingImage)
            return;
        const imagePos = mapState.addingImage.position as LatLng
        const r = [...mapState.polylines.filter(line => {
            let isNearby = false;
            line.points.forEach(p => {
                if (imagePos.distanceTo(p) < MapLayer.addImageRoutesListProximity) {
                    isNearby = true;
                }
            })
            return isNearby;
        })]
        r.sort((a, b) => MapHelpers.sortDistanceToPointByNearestNode(a, b, imagePos))
        updateRoutes(r);
    }

    useEffect(() => {
        setUpToggleAddImage();
        setRoutesNearImage();
    }, [mapState])



    const headerColStyle: React.CSSProperties = {
        padding: '0 10px'
    }


    const convertToUploadFile = (file: RcFile): UploadFile => {
        const uploadFile: UploadFile = {
            uid: file.uid,
            name: file.name,
            size: file.size,
            type: file.type,
            originFileObj: file,
            status: 'done', // You may set status based on your requirements
            response: '', // You may initialize response based on your requirements
        };
        return uploadFile;
    }


    const onImageSelected = async (file: RcFile, FileList: RcFile[]) => {
        if (!mapState.addingImage) {
            updateImages([])
            return false;
        }

        console.log('Before Process: ', file.size)

        setIsProcessing(true)
        const onProcessingFinished = () => {
            setIsProcessing(false)
        }
        FileHelpers.resizeImage(file, 500, 1, (file) => {
            if (file) {
                console.log('After Process: ', file.size)
                updateImages([convertToUploadFile(file)]);

                let routeId = (mapState.addingImage?.routeId && mapState.addingImage?.routeId > 0)
                    ? mapState.addingImage.routeId // keep currently selected route
                    : routes.length === 1 ? routes[0].id : 0 // if only one route nearby, assign that, otherwise 0
                updateMapState({
                    ...mapState,
                    addingImage: {
                        ...mapState.addingImage as IMapImage,
                        file: file,
                        routeId: routeId,
                    }
                })
            } else {
                app.notification.error({
                    placement: 'top',
                    message: 'Image too large'
                })
            }
            onProcessingFinished();
        })

        return false;
    }

    const onSelectImageClicked = () => {
        // Trigger the file selection dialog
        uploadRef.current?.click();
    };

    const onRowClicked = (r: ISearchResultTableItem) => {
        ReactGA.event({
            category: 'Click',
            action: 'Add Image - Routes List - Clicked Route',
            label: `${r.id}: ${r.name}`
        });
        updateMapState({
            ...mapState,
            addingImage: {
                ...mapState.addingImage as IMapImage,
                routeId: r.id
            }
        })
        // routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: r.id })
    }

    const onDrawerClose = async (): Promise<void> => {
        if (isProcessing) {
            return;
        }
        const confirmed = await MapActions.cancelAddImageMode();
        if (confirmed) {
            updateImages([]);
        }

    }

    const onRemovePhoto = (file: UploadFile<any>): boolean | void | Promise<boolean | void> => {
        updateImages([])
        updateMapState({
            ...mapState,
            addingImage: {
                ...mapState.addingImage as IMapImage,
                file: null
            }
        })
    }

    const onImageDateChanged = (m: number | null, y: number | null) => {

        if (m !== null) {
            setImgMonth(m)
        }
        if (y !== null) {
            setImgYear(y)
        }
        if (m !== null && y !== null) {
            const imgDate = new Date(y, m, 1)
            updateMapState({
                ...mapState,
                addingImage: {
                    ...mapState.addingImage as IMapImage,
                    createdDate: imgDate
                }
            })
        }
    }

    const requiredStyle: React.CSSProperties = {
        fontSize: '12px',
        color: 'red',
        fontWeight: 'normal'
    }

    let drawerHeader: JSX.Element | null = null
    let drawerContent: JSX.Element = <></>

    if (!appState.account.isLoggedIn) {
        drawerContent = <Result
            status="warning"
            icon={null}
            title="Not Logged In"
            subTitle="You nned to have an account to add images"
            extra={<Login />}
        />
    }
    else if (routes.length === 0) {
        drawerContent = <Result
            status="warning"
            title="Cannot add image"
            extra="Please place image at an existing route"
        />
    }
    else {
        drawerHeader = <Row>
            <Col span={12} style={headerColStyle}>
                <Button type="default"
                    className="full-width"
                    size={isMobile ? 'middle' : 'middle'}
                    icon={Icons.AddImageIcon}
                    disabled={routes.length === 0 || isProcessing}
                    onClick={onSelectImageClicked}>
                    Select
                </Button>
            </Col>
            <Col span={12} style={headerColStyle}>
                <Button
                    className="full-width"
                    onClick={MapActions.saveImage}
                    size={isMobile ? 'middle' : 'middle'}
                    icon={Icons.FinishDrawRouteIcon}
                    disabled={routes.length === 0 || isProcessing}
                    type="primary">
                    {isMobile ? 'Save' : 'Save'}
                </Button>
            </Col>
        </Row>
        drawerContent = <>
            <Space.Compact direction="vertical" style={{
                display: 'flex',
                padding: '10px'
            }}>
                {
                    !isProcessing &&
                    <Upload
                        listType="picture"
                        multiple={false}
                        defaultFileList={images}
                        onRemove={onRemovePhoto}
                        accept=".jpg, .jpeg, .png"
                        beforeUpload={onImageSelected}
                        openFileDialogOnClick
                        fileList={[...images]}
                        style={{}}
                    >
                        <div ref={uploadRef}>
                            {/* Leave empty. Acts as ref for programatically clicking the upload component*/}
                        </div>
                    </Upload>
                }

                {
                    (mapState.addingImage?.file && !isProcessing) &&
                    <>
                        <Space.Compact className="full-width" style={{ margin: '4px 0' }}>
                            <Space>
                                <span style={{ fontWeight: 'bold' }}>Image Date: </span>
                                {mapState.addingImage.createdDate === null && <span style={{ ...requiredStyle, marginRight: '10px' }}> (required)</span>}
                            </Space>

                            <Select onChange={(e) => onImageDateChanged(e, imgYear)}
                                placeholder={'Month'}
                                defaultValue={mapState.addingImage?.createdDate?.getMonth()}
                                options={DateHelpers.getMonths().map((m, i) => {
                                    return {
                                        value: m.month,
                                        label: m.name
                                    }
                                })} />
                            <Select onChange={(e) => onImageDateChanged(imgMonth, e)}
                                placeholder={'Year'}
                                defaultValue={mapState.addingImage?.createdDate?.getFullYear()}
                                options={DateHelpers.getYears(new Date().getFullYear() - 10).map((y, i) => {
                                    return {
                                        value: y,
                                        label: `${y}`
                                    }
                                })} />
                        </Space.Compact>
                        <Alert style={{ marginTop: '5px' }} message="Please select a nearby route that the photo should be associated with" type="info" />
                    </>
                }
            </Space.Compact>
            <div style={{
                paddingBottom: '15px'
            }
            }>
                {
                    mapState.addingImage?.file && !isProcessing
                        ? <RoutesList onAddPhoto forceVirtual showHeader={false} onRowClicked={onRowClicked} routes={routes} />
                        : <Empty image={
                            isProcessing
                                ? <Spin style={{ marginTop: '10px' }} />
                                : <span onClick={() => onSelectImageClicked()}>
                                    {Empty.PRESENTED_IMAGE_SIMPLE}
                                </span>
                        } description={
                            isProcessing
                                ? <div >Processing</div>
                                : <div >Select a Photo</div>
                        } />
                }
            </div>
        </>
    }

    return <Drawer
        destroyOnClose
        onClose={onDrawerClose}
        title={drawerHeader}
        placement={'bottom'}
        closeIcon={true}
        mask={false}
        open={open}
        styles={{
            ...UiHelpers.getBottomMenuStyle(isMobile, mobileBreakPoint),
            wrapper: {
                boxShadow: 'none',
                height: isMobile ? 'auto' : 'auto',
                width: isMobile ? 'auto' : mobileBreakPoint,
                margin: 'auto'
            },
        }}>
        {
            drawerContent
        }


    </Drawer>
}

export default AddImage