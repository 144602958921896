import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import { Icons } from "../ui/Icons";
import { themeConstants, themeStyles } from "../../../constants/theme";
import { DivIcon, LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { useMap, useMapEvents, Marker, Tooltip } from "react-leaflet";
import { useMapContext } from "../../../contexts/MapContext";
import { IPolylineModel, PolylineType } from "../../../models/map/PolylineModel";
import { MapHelpers } from "../helpers/MapHelpers";
import { MapInteractionMode } from "../../../models/map/MapState";
import { MapLayer } from "../helpers/MapLayer";
import { useAppContext } from "../../../contexts/AppContext";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'

const offRoadIcon = document.createElement('div');
const hikingIcon = document.createElement('div');
const mountainBikeIcon = document.createElement('div');
const dirtBikeIcon = document.createElement('div');
const offRoadIconRoot = createRoot(offRoadIcon);
const hikingIconRoot = createRoot(hikingIcon);
const mountainBikeIconRoot = createRoot(mountainBikeIcon);
const dirtBikeIconRoot = createRoot(dirtBikeIcon);
flushSync(() => {
    const markerStyle: React.CSSProperties = {
        fontSize: '23px',
        borderRadius: '30px',
        color: 'white',
        transform: `translate(${themeConstants.iconAnchor.x}, ${themeConstants.iconAnchor.y})`,
        height: '30px',
        width: '30px',
        textAlign: 'center'
    }
    offRoadIconRoot.render(<div style={{
        ...markerStyle,
        backgroundColor: themeStyles.routeColours.offRoad
    }}>
        {Icons.OffRoadIcon}
    </div>);
    hikingIconRoot.render(<div style={{
        ...markerStyle,
        backgroundColor: themeStyles.routeColours.hiking
    }}>
        {Icons.HikingIcon}
    </div>);
    mountainBikeIconRoot.render(<div style={{
        ...markerStyle,
        backgroundColor: themeStyles.routeColours.mountainBike,
    }}>
        {Icons.MountainBikeIcon}
    </div>);
    dirtBikeIconRoot.render(<div style={{
        ...markerStyle,
        backgroundColor: themeStyles.routeColours.dirtbike,
    }}>
        {Icons.MountainBikeIcon}
    </div>);
});


export const RouteMarkers = () => {

    const { mapState, updateMapState } = useMapContext();
    const map = useMap();
    const { appState } = useAppContext();
    const [zoom, setZoom] = useState(mapState.selectedMap.crsOptions.zoom)
    const navigate = useNavigate();
    const location = useLocation();
    const [markerData, setMarkerData] = useState<{
        position: L.LatLng;
        name: string,
        type: PolylineType,
        id: number
    }[]>([])

    useEffect(() => {
        updateMarkerData();
    }, [mapState, zoom])

    useMapEvents({
        zoomend() {
            setZoom(map.getZoom())
            updateMarkerData()
        },
        moveend() {
            setZoom(map.getZoom())
            updateMarkerData()
        }
    })

    const withinZoomLimit = () => {
        return map.getZoom() < MapLayer.routesRenderLimit
            && map.getZoom() >= MapLayer.markersRenderLimit;

    }

    const shouldMarkerRender = (markerPolyline: IPolylineModel) => {
        if (mapState.interactionMode === MapInteractionMode.DrawRoute) {
            return false;
        }

        if (!withinZoomLimit()) {
            return false;
        }

        const allowedRadius = MapLayer.routeMarkersRenderRadius;
        const bounds = map.getBounds();
        const centre = bounds.getCenter();

        return markerPolyline.points.filter(point => centre.distanceTo(point) <= allowedRadius).length > 0
    }

    const updateMarkerData = () => {
        let filteredRoutes = mapState.polylines;
        filteredRoutes = filteredRoutes.filter(r => shouldMarkerRender(r))
        filteredRoutes = MapHelpers.applyPendingPolylineFilter(filteredRoutes, appState.account);
        filteredRoutes = MapHelpers.applyFilter(filteredRoutes, mapState.filter);
        const _markerData = filteredRoutes.map((r: IPolylineModel, i) => {
            return {
                position: MapHelpers.getBounds(r.points).getCenter(),
                name: r.name,
                type: r.polylineType,
                id: r.id
            }
        })
        setMarkerData(_markerData);
    }

    const onMarkerClicked = (id: number) => {
        const clickedPolyline = mapState.polylines.filter(p => p.id === id)[0]
        if (!clickedPolyline)
            return;

        if (mapState.interactionMode === MapInteractionMode.Normal) {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('routeId', `${id}`);
            queryParams.delete('q');
            navigate(`${location.pathname}?${queryParams.toString()}`);
            updateMapState({
                ...mapState,
                clickedPolylineId: id,
            })
            ReactGA.event({
                category: 'Map Interaction',
                action: `Clicked Marker (${MapInteractionMode[mapState.interactionMode]})`,
                label: `${clickedPolyline.id}: ${clickedPolyline.name}`
            });
        }
    }

    return <>
        {
            markerData.map((m, i) => {
                let divIcon = new DivIcon()
                switch (m.type) {
                    case PolylineType.OffRoad: divIcon = new DivIcon({ html: offRoadIcon.innerHTML }); break;
                    case PolylineType.Hiking: divIcon = new DivIcon({ html: hikingIcon.innerHTML }); break;
                    case PolylineType.MountainBike: divIcon = new DivIcon({ html: mountainBikeIcon.innerHTML }); break;
                    case PolylineType.DirtBike: divIcon = new DivIcon({ html: dirtBikeIcon.innerHTML }); break;
                }
                return <Marker
                    eventHandlers={{
                        mouseup(e) {
                            if (e.originalEvent.button === 0) {
                                // 0 == left click
                                onMarkerClicked(m.id)
                            }
                        }
                    }}
                    position={m.position}
                    icon={divIcon}
                    key={`line-marker-${i}-${zoom}`} >
                    <Tooltip sticky>
                        {m.name}
                    </Tooltip>
                </Marker>
            })
        }
    </>
}

export default RouteMarkers