import { RcFile } from "antd/es/upload";
import { ApiResult, IApiResult } from "../models/api/apiResult";
import { IMapImage } from "../models/map/MapImage";
import { PolylineImage } from "../models/map/PolylineImage";
import { getUserTokenCookie, handleApiResultToken } from "./clientHelpers";

const deleteImageUrl = 'images/delete-image'
const approveImageUrl = 'images/approve-image'
const uploadMapImageUrl = 'images/upload-image'
const setMainImageUrl = 'images/set-main-image'

export const uploadMapImage = async (mapImage: IMapImage): Promise<IApiResult<PolylineImage>> => {

    const formData = new FormData();
    formData.append('latitude', mapImage.position?.lat.toString() ?? '');
    formData.append('longitude', mapImage.position?.lng.toString() ?? '');
    formData.append('files', mapImage.file as RcFile);
    formData.append('routeId', mapImage.routeId?.toString() ?? '');
    formData.append('createdDate', mapImage.createdDate?.toDateString() ?? new Date().toDateString());

    try {

        const response = await fetch(process.env.REACT_APP_BASE_URL + uploadMapImageUrl, {
            method: 'POST',
            mode: 'cors',
            body: formData,
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            }
        });

        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<PolylineImage> = json as IApiResult<PolylineImage>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    } catch (error) {
        console.error(error);
        return new ApiResult<PolylineImage>(false);
    }
}

export const deleteImage = async (id: number): Promise<IApiResult<number>> => {
    try {

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${deleteImageUrl}/${id}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<number> = json as IApiResult<number>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<number>(false);
    }
}

export const approveImage = async (id: number): Promise<IApiResult<number>> => {
    try {

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${approveImageUrl}/${id}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<number> = json as IApiResult<number>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<number>(false);
    }
}

export const setMainImage = async (id: number): Promise<IApiResult<PolylineImage[]>> => {
    try {

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${setMainImageUrl}/${id}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<PolylineImage[]> = json as IApiResult<PolylineImage[]>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<PolylineImage[]>(false);
    }
}