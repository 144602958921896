import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
    LoginSocialTiktok,
    IResolveParams,
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
    AppleLoginButton,
} from 'react-social-login-buttons'
import { useAppContext } from "../../contexts/AppContext"
import { useState, useCallback } from 'react';
import { App, Button, Modal, Space } from 'antd';
import { IFBLoginResponse, IFBProfileData, IGoogleLoginResponse, OAuthType } from '../../models/loginModels/LoginModels';
import { IUserModel, UserModel } from '../../models/general/User';
import { IApiResult } from '../../models/api/apiResult';
import { verifyOath } from '../../client/accountClient';
import Title from 'antd/es/typography/Title';
import '../../css/app.css'
import ReactGA from 'react-ga4'

// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes
const REDIRECT_URI = window.location.href;

interface IProps {
    buttonStyle?: React.CSSProperties
    onLoginComplete?: () => void
}

export const Login = (props: IProps) => {
    const { appState, updateAppState } = useAppContext();
    const app = App.useApp();

    const [modalOpen, setModalOpen] = useState(false)

    const handleLoginResult = (result: IApiResult<IUserModel>, provider: string) => {
        if (result.isSuccess && result.data) {
            props.onLoginComplete?.();
            ReactGA.event({
                category: 'User',
                action: 'Logged in with ' + provider
            });
            updateAppState({
                ...appState,
                account: result.data
            })
            app.notification.success({
                message: 'Login Complete',
                placement: 'top'
            })
        }
        else {
            app.notification.error({
                message: 'Login Error',
                placement: 'top'
            })
        }
    }


    return (
        <>
            <Button type='primary'
                onClick={() => setModalOpen(true)}
                size='large'
                hidden={modalOpen}
                style={props.buttonStyle}
            >
                Login
            </Button>
            <Modal
                open={modalOpen}
                closable={true}
                onCancel={() => setModalOpen(false)}
                centered
                closeIcon={false}
                styles={{
                    body: {
                        textAlign: 'center'
                    }
                }}
                footer={<></>}
            >
                <Space direction='vertical' align='center' className='login-body'>
                    <Title level={4}>
                        Log in to off-road-map.uk
                    </Title>
                    <LoginSocialFacebook
                        isOnlyGetToken
                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                        onResolve={async ({ provider, data }: IResolveParams) => {
                            let fbdata = data as IFBLoginResponse;

                            await FB.api('/me', { fields: 'name, email' }, async (resp: IFBProfileData) => {
                                const loginResult = await verifyOath({
                                    accessToken: fbdata.accessToken,
                                    email: resp.email,
                                    name: resp.name,
                                    oAuthType: OAuthType.Facebook
                                });
                                handleLoginResult(loginResult, provider)
                            });
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <FacebookLoginButton />
                    </LoginSocialFacebook>

                    <LoginSocialGoogle
                        isOnlyGetToken
                        scope='email'
                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        onResolve={async ({ provider, data }: IResolveParams) => {
                            const googleData = data as IGoogleLoginResponse;
                            const loginResult = await verifyOath({
                                accessToken: googleData.access_token,
                                email: '',
                                name: '',
                                oAuthType: OAuthType.Google
                            })
                            handleLoginResult(loginResult, provider)
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <GoogleLoginButton />
                    </LoginSocialGoogle>

                    {/* <LoginSocialApple
                        client_id={process.env.REACT_APP_APPLE_ID || '0'}
                        scope={'name email'}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            console.log(provider);
                            console.log(data);
                        }}
                        onReject={err => {
                            console.log(err);
                        }}
                    >
                        <AppleLoginButton />
                    </LoginSocialApple> */}

                    {/* <LoginSocialInstagram
                        isOnlyGetToken
                        client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || '0'}
                        client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || '0'}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            console.log(provider);
                            console.log(data);
                        }}
                        onReject={(err: any) => {
                            console.log(err)
                        }}
                    >
                        <InstagramLoginButton />
                    </LoginSocialInstagram> */}

                    {/* <LoginSocialMicrosoft
                        isOnlyGetToken
                        client_id={process.env.REACT_APP_MICROSOFT_APP_ID || '0'}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            console.log(provider);
                            console.log(data);
                        }}
                        onReject={(err: any) => {
                            console.log(err)
                        }}
                    >
                        <MicrosoftLoginButton />
                    </LoginSocialMicrosoft> */}


                    {/* <LoginSocialGithub
                        isOnlyGetToken
                        client_id={process.env.REACT_APP_GITHUB_APP_ID || '0'}
                        client_secret={process.env.REACT_APP_GITHUB_APP_SECRET || '0'}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            console.log(provider);
                            console.log(data);
                        }}
                        onReject={(err: any) => {
                            console.log(err)
                        }}
                    >
                        <GithubLoginButton />
                    </LoginSocialGithub> */}
                </Space>
            </Modal>
        </>
    )
}

export default Login