import { IMapState, MapInteractionMode, MapState } from "../../../models/map/MapState";
import { IPolylineModel, PolylineModel, PolylineType, TRO_Type } from "../../../models/map/PolylineModel";
import { themeStyles } from "../../../constants/theme";
import { IAppState } from "../../../contexts/AppContext";
import { savePolyline, updatePolyline } from "../../../client/mapclient";
import { useAppProps } from "antd/es/app/context";
import { EventNames, addImageEventEmitter, drawRouteDetailsEventEmitter, routeFinderEventEmitter, routeFinderItemClickedEventEmitter } from "./EventEmitter";
import { RouteFilter, SortOption } from "../../../models/map/RouteFilter";
import { IMapImage, MapImage } from "../../../models/map/MapImage";
import { LatLng } from "leaflet";
import { PolylineImage } from "../../../models/map/PolylineImage";
import { MapHelpers } from "./MapHelpers";
import { uploadMapImage } from "../../../client/imageClient";

export interface IMapActionsInitModel {
    app: useAppProps
    mapState: IMapState,
    updateMapState: (newState: IMapState) => void
    appState: IAppState;
    updateAppSate: (newState: IAppState) => void;
}

export class MapActions {

    private static app: useAppProps;
    private static appState: IAppState;
    private static updateAppSate: (newState: IAppState) => void;
    private static mapState: IMapState;
    private static updateMapState: (newState: IMapState) => void;



    static init(model: IMapActionsInitModel) {
        MapActions.app = model.app;
        MapActions.mapState = model.mapState;
        MapActions.updateMapState = model.updateMapState;
        MapActions.appState = model.appState;
        MapActions.updateAppSate = model.updateAppSate
    }

    static beginDrawRouteMode(): void {
        MapActions.updateMapState({
            ...MapActions.mapState,
            showContextMenu: false,
            interactionMode: MapInteractionMode.DrawRoute,
            isEditMode: true,
            editingPolyline: new PolylineModel(PolylineType.Unknown),
            searchResults: [],
            filter: new RouteFilter(),
            clickedPolylineId: 0
        });
        addImageEventEmitter.emit(EventNames.toggleAddImage, { open: false })
        routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: false })
        drawRouteDetailsEventEmitter.emit(EventNames.toggleDrawRouteDetails, { open: true })
    }

    static setSelectedSearchLocation(): void {
        MapActions.updateMapState({
            ...MapActions.mapState,
            showContextMenu: false,
            clickedPolylineId: 0,
            searchResults: [],
            filter: {
                ...MapActions.mapState.filter,
                searchPoint: MapActions.mapState.contextMenuPos,
                sort: SortOption.SelectedLocation
            }
        });

        routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: true })
    }

    static async saveImage(): Promise<void> {
        let image = MapActions.mapState.addingImage as IMapImage;

        if (!MapActions.validateMapImage(image))
            return;

        let route = MapActions.mapState.polylines.find(p => p.id === image.routeId);
        if (!route)
            return;

        MapActions.updateAppSate({
            ...MapActions.appState,
            isLoading: true
        })

        const apiResult = await uploadMapImage(image)

        if (apiResult.isSuccess && apiResult.data) {
            MapActions.addImageToAccount(apiResult.data);
            MapActions.updateMapState({
                ...MapActions.mapState,
                interactionMode: MapInteractionMode.Normal,
                addingImage: null,
            })
            MapActions.app.modal.success({
                title: 'Image Added',
                content: <>Thanks! Your image has been submitted for review</>,
                centered: true
            })
            setTimeout(() => {
                addImageEventEmitter.emit(EventNames.toggleAddImage, { open: false })
            }, 1);
        }
        else {
            MapActions.app.notification.error({
                message: 'Saving Failed',
                placement: 'top'
            })
        }
        setTimeout(() => {
            MapActions.updateAppSate({
                ...MapActions.appState,
                isLoading: false,
            })
        }, 1);

    }

    static async cancelAddImageMode(): Promise<boolean> {
        const cancelAddImage = (): boolean => {
            MapActions.updateMapState({
                ...MapActions.mapState,
                showContextMenu: false,
                interactionMode: MapInteractionMode.Normal,
                addingImage: null,
                isEditMode: false,
                filter: new RouteFilter()
            });
            setTimeout(() => {
                addImageEventEmitter.emit(EventNames.toggleAddImage, { open: false })
            }, 1);
            return true;
        }

        if (MapActions.mapState.addingImage?.file) {
            const confirmed = await MapActions.app.modal.confirm({
                title: 'Cancel add image',
                content: 'Lose unsaved changes?',
                centered: true,
                cancelText: 'Cancel',
                closeIcon: null,
                maskClosable: true,
                okButtonProps: {
                    style: {
                        backgroundColor: themeStyles.colorWarn
                    }
                },
                okText: 'Confirm',
                onOk: (close) => {
                    cancelAddImage()
                    close();
                }
            }).then((confirmed) => { return confirmed }, () => { return false });
            return confirmed;
        }
        else {
            return cancelAddImage()
        }
    }

    static addImageToAccount(image: PolylineImage) {
        MapActions.updateAppSate({
            ...MapActions.appState,
            account: {
                ...MapActions.appState.account,
                images: [...MapActions.appState.account.images, image],
            }
        })
    }


    static cancelDrawRouteMode(): void {
        const cancelDrawRoute = () => {
            MapActions.updateMapState({
                ...MapActions.mapState,
                showContextMenu: false,
                interactionMode: MapInteractionMode.Normal,
                editingPolyline: null,
                isEditMode: false
            });
            setTimeout(() => {
                drawRouteDetailsEventEmitter.emit(EventNames.toggleDrawRouteDetails, { open: false })
            }, 1);
        }
        if (MapActions.mapState.editingPolyline && MapActions.mapState.editingPolyline.points.length > 0) {
            MapActions.app.modal.confirm({
                title: 'Stop Drawing Route',
                content: 'Lose unsaved changes?',
                centered: true,
                cancelText: 'Cancel',
                closeIcon: null,
                maskClosable: true,
                okButtonProps: {
                    style: {
                        backgroundColor: themeStyles.colorWarn
                    }
                },
                okText: 'Confirm',
                onOk: cancelDrawRoute
            });
        }
        else {
            cancelDrawRoute()
        }
    }

    static addPolylineToMap(polyline: IPolylineModel) {

        let newPolyline = {
            ...polyline
        }

        let updatedPolylines = [...MapActions.mapState.polylines];
        updatedPolylines.push(newPolyline)

        MapActions.updateMapState({
            ...MapActions.mapState,
            editingPolyline: null,
            polylines: updatedPolylines,
            isEditMode: false,
            interactionMode: MapInteractionMode.Normal
        })
    }

    static updatePolylineOnMap(polyline: IPolylineModel) {

        let newPolyline = {
            ...polyline
        }

        let index = MapActions.mapState.polylines.findIndex(p => p.id === newPolyline.id)

        let updatedPolylines = [...MapActions.mapState.polylines];
        updatedPolylines.splice(index, 0, newPolyline)

        MapActions.updateMapState({
            ...MapActions.mapState,
            editingPolyline: null,
            polylines: updatedPolylines,
            isEditMode: false,
            interactionMode: MapInteractionMode.Normal
        })
    }


    private static validatePolyline(polyline: IPolylineModel): boolean {

        let isValid = true;

        if (!(polyline.points.length > 1)) {
            MapActions.app.notification.warning({
                message: 'Route incomplete',
                placement: 'top'
            })
            return false;
        }

        if (polyline.name.length === 0) {
            MapActions.app.notification.warning({
                message: 'Route Name required',
                placement: 'top'
            })
            isValid = false;
        }

        if (polyline.name.length > MapHelpers.maxRouteNameLength) {
            MapActions.app.notification.warning({
                message: 'Route Name too long',
                placement: 'top'
            })
            isValid = false;
        }

        if (polyline.polylineType === PolylineType.Unknown) {
            MapActions.app.notification.warning({
                message: 'Route Type required',
                placement: 'top'
            })
            isValid = false;
        }

        if ((polyline.polylineType === PolylineType.OffRoad || polyline.polylineType === PolylineType.DirtBike)) {
            if (polyline.roadType == null) {
                MapActions.app.notification.warning({
                    message: 'Accessibility required',
                    placement: 'top'
                })
                isValid = false;
            }
            if (polyline.hasTRO) {
                if (polyline.trO_Type === null) {
                    MapActions.app.notification.warning({
                        message: 'TRO Type required',
                        placement: 'top'
                    })
                    isValid = false;
                }
                if (polyline.trO_Type === TRO_Type.Temporary && polyline.trO_EndDate === null) {
                    MapActions.app.notification.warning({
                        message: 'TRO End Date required',
                        placement: 'top'
                    })
                    isValid = false;
                }
            }
        }

        return isValid;
    }

    private static validateMapImage(image: IMapImage): boolean {

        let isValid = true;

        if (image.routeId === 0) {
            MapActions.app.notification.warning({
                message: 'Image not associated with route',
                placement: 'top'
            })
            isValid = false;
        }

        if (image.createdDate === null) {
            MapActions.app.notification.warning({
                message: 'Image date required',
                placement: 'top'
            })
            isValid = false;
        }

        if (!image.position) {
            MapActions.app.notification.warning({
                message: 'Image has no position',
                placement: 'top'
            })
            isValid = false;
        }

        if (!image.file || image.file.size === 0) {
            MapActions.app.notification.warning({
                message: 'No image file!',
                placement: 'top'
            })
            isValid = false;
        }

        return isValid;
    }

    static async finishDrawRouteMode(): Promise<void> {

        let polyline = MapActions.mapState.editingPolyline as IPolylineModel;

        if (!MapActions.validatePolyline(polyline))
            return;

        MapActions.updateAppSate({
            ...MapActions.appState,
            isLoading: true
        })

        const apiResult = await savePolyline(polyline)

        if (apiResult.isSuccess && apiResult.data) {
            MapActions.addPolylineToMap(apiResult.data)
            MapActions.app.modal.success({
                title: 'Route Added',
                content: <>Thanks! Your route has been submitted for review</>,
                centered: true
            })
            setTimeout(() => {
                const newRouteId = MapActions.mapState.polylines[MapActions.mapState.polylines.length - 1].id;
                MapActions.addPolylineToUser(newRouteId)
                drawRouteDetailsEventEmitter.emit(EventNames.toggleDrawRouteDetails, { open: false })
                routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: newRouteId })
            }, 1);
        }
        else {
            MapActions.app.notification.error({
                message: 'Saving Failed',
                placement: 'top'
            })
        }

        MapActions.updateAppSate({
            ...MapActions.appState,
            isLoading: false
        })

    }

    static async updatePolyline(p: IPolylineModel): Promise<void> {

        if (!MapActions.validatePolyline(p))
            return;

        MapActions.updateAppSate({
            ...MapActions.appState,
            isLoading: true
        })

        const apiResult = await updatePolyline(p)

        if (apiResult.isSuccess && apiResult.data) {
            MapActions.updatePolylineOnMap(apiResult.data)
            MapActions.app.notification.success({
                message: 'Route Updated',
                placement: 'top'
            })
            setTimeout(() => {
                drawRouteDetailsEventEmitter.emit(EventNames.toggleDrawRouteDetails, { open: false })
                routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: p.id })
            }, 1);
        }
        else {
            MapActions.app.notification.error({
                message: 'Updating Failed',
                placement: 'top'
            })
        }

        MapActions.updateAppSate({
            ...MapActions.appState,
            isLoading: false
        })

    }

    static addPolylineToUser(id: number) {
        MapActions.updateAppSate({
            ...MapActions.appState,
            account: {
                ...MapActions.appState.account,
                routeIDs: [...MapActions.appState.account.routeIDs, id]
            }
        })
    }

    static addImageClicked(): void {
        let filter = new RouteFilter();
        filter.sort = SortOption.SelectedLocation
        MapActions.updateMapState({
            ...MapActions.mapState,
            showContextMenu: false,
            interactionMode: MapInteractionMode.AddImage,
            editingPolyline: new PolylineModel(PolylineType.Unknown),
            searchResults: [],
            filter: filter,
            addingImage: new MapImage(MapActions.mapState.contextMenuPos as LatLng),
            clickedPolylineId: 0
        });
        setTimeout(() => {
            addImageEventEmitter.emit(EventNames.toggleAddImage, { open: true })
            routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: false })
        }, 100);
    }

    static undoClicked(): void {
        let polyline = MapActions.mapState.editingPolyline as IPolylineModel

        if (polyline && polyline.points && polyline.points.length > 0) {
            polyline.points.pop();

            MapActions.updateMapState({
                ...MapActions.mapState,
                editingPolyline: polyline,
                showContextMenu: false,
            })
        }
        else {
            MapActions.updateMapState({
                ...MapActions.mapState,
                showContextMenu: false,
            })
        }
        return;
    }


    static hasEditingPolylinePoints = () => {
        if (!MapActions.mapState.editingPolyline)
            return false;

        return MapActions.mapState.editingPolyline.points.length > 0
    }

    static onLogout = () => {
        MapActions.updateMapState({
            ...MapActions.mapState,
            interactionMode: MapInteractionMode.Normal,
            editingPolyline: null,
            isEditMode: false,
            addingImage: null,
        })
    }
}