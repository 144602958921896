import { Popup, Tooltip } from "react-leaflet";
import { useMapContext } from "../../../contexts/MapContext";
import { Icons } from "../ui/Icons";
import '../../../css/map.css'
import { click } from "@testing-library/user-event/dist/click";
import { MapActions } from "../helpers/MapActions";
import { routeFinderEventEmitter, EventNames } from "../helpers/EventEmitter";
import { SortOption } from "../../../models/map/RouteFilter";

export const SearchPointPopup = () => {

    const { mapState, updateMapState } = useMapContext();

    if (mapState.filter.searchPoint === null) {
        return <></>
    }

    const onClick = () => {
        updateMapState({
            ...mapState,
            clickedPolylineId: 0,
            filter: {
                ...mapState.filter,
                sort: SortOption.SelectedLocation
            }
        })
        routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: true })
    }

    return <Popup
        className="primary-popup hover-pointer"
        autoPan={false}
        closeOnClick={false}
        position={mapState.filter.searchPoint}
        autoClose={false}
        closeButton={false}
    >
        <div onClick={onClick}>
            {Icons.RouteFinderIcon}
        </div>
    </Popup >
}

export default SearchPointPopup