import { DrawerStyles } from "antd/es/drawer/DrawerPanel";
import { themeConstants } from "../constants/theme";

export class UiHelpers {

    private static easeInOutQuad(t: number): number {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    static drawerOpacity = 0.95;

    static getBottomMenuStyle(isMobile: boolean, mobileBreakPoint: number): DrawerStyles {
        return {
            header: {
                padding: isMobile ? '10px' : '10px'
            },
            footer: {
                display: 'none'
            },
            body: {
                padding: 0,
                width: 'auto',
                height: isMobile ? 'calc(50vh - 45px)' : 'auto'
            },
            wrapper: {
                boxShadow: isMobile ? 'none' : '0 0 15px 1px grey',
                bottom: isMobile ? '-50px' : '-50px',
                width: isMobile ? 'auto' : mobileBreakPoint,
                margin: 'auto',
                opacity: UiHelpers.drawerOpacity
            }
        }
    }

    static getRightMenuStyle(isMobile: boolean): DrawerStyles {
        return {
            header: {
                padding: isMobile ? '3px 10px' : '10px'
            },
            footer: {
                display: 'none'
            },
            body: {
                padding: 0,
                width: 'auto',
                height: isMobile ? 'calc(50vh - 45px)' : 'auto'
            },
            wrapper: {
                boxShadow: isMobile ? 'none' : '0 0 15px 1px grey',
                height: isMobile ? 'auto' : 'calc(-100px + 100vh)',
                transform: isMobile ? '' : 'translateY(100px)',
                width: isMobile ? '100%' : '378px',
                opacity: UiHelpers.drawerOpacity
            },
            content: {
                position: isMobile ? 'fixed' : 'absolute',
                bottom: isMobile ? '0' : '0',
                height: isMobile ? '275px' : 'calc(100vh - 100px)',
                width: isMobile ? '100%' : '378px',
            },
        }
    }

    static animateHeight(element: HTMLElement, targetHeight: number, duration: number) {
        const startHeight = element.offsetHeight;
        const startTime = performance.now();

        function updateHeight() {
            const elapsedTime = performance.now() - startTime;
            const progress = elapsedTime / duration;

            // Apply the easing function to the progress
            const easedProgress = UiHelpers.easeInOutQuad(progress);

            const newHeight = startHeight + (targetHeight - startHeight) * easedProgress;

            // Apply the new height to the element
            element.style.height = `${newHeight}px`;

            // Continue animating if not reached the target height
            if (elapsedTime < duration) {
                requestAnimationFrame(updateHeight);
            }
            else {
                element.style.height = `${targetHeight}px`;
            }

        }

        // Start the animation
        requestAnimationFrame(updateHeight);
    }

    static getDrawnRouteIconStyle(): React.CSSProperties {
        return {
            borderRadius: '30px',
            color: 'white',
            backgroundColor: 'white',
            fontSize: '15px',
            transform: `translate(-30, -10)`,
            height: '20px',
            width: '20px',
            textAlign: 'center',
        }
    }
}