import { Divider, Tabs } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import SubmissionsRoutes from "../components/general/SubmissionsRoutes";
import SubmissionsImages from "../components/general/SubmissionsImages";
import { Icons } from "../components/map/ui/Icons";
import { useAppContext } from "../contexts/AppContext";
import { UserType } from "../models/general/User";
import '../css/pages.css'
import ReactGA from 'react-ga4'

export function MySubmissions() {
    ReactGA.send({ hitType: "pageview", page: "/my-submissions", title: "Submissions" });

    const { appState } = useAppContext();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <>
        <div className="container">
            <Title>{appState.account.userType === UserType.Public ? 'My Submissions' : 'Submissions'}</Title>

            <Divider />

            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        key: '1',
                        label: 'Routes',
                        children: <SubmissionsRoutes />,
                        icon: Icons.DrawRouteModeIcon
                    },
                    {
                        key: '2',
                        label: 'Images',
                        children: <SubmissionsImages />,
                        icon: Icons.AddImageIcon
                    }
                ]}

            />
        </div>
    </>
}

export default MySubmissions


