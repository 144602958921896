import { Button, Checkbox, Col, ConfigProvider, Drawer, Input, Row, Select, Space, Tag, Tooltip } from "antd";
import { useMapContext } from "../../../contexts/MapContext"
import { ChangeEvent, useEffect, useState } from "react";
import { MapHelpers } from "../helpers/MapHelpers";
import { ThemeHelpers } from "../../../helpers/ThemeHelpers";
import '../../../css/routeFinder.css'
import { PolylineType } from "../../../models/map/PolylineModel";
import { EventNames, routeFinderEventEmitter, routeFinderItemClickedEventEmitter } from "../helpers/EventEmitter";
import { RouteFilter, SortOption } from "../../../models/map/RouteFilter";
import { useWindowContext } from "../../../contexts/WindowContext";
import { MapActions } from "../helpers/MapActions";
import { MapInteractionMode } from "../../../models/map/MapState";
import RoutesList, { ISearchResultTableItem } from "./RoutesList";
import { UiHelpers } from "../../../helpers/UiHelpers";
import { useAppContext } from "../../../contexts/AppContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Icons } from "./Icons";
import ReactGA from 'react-ga4'


export const RouteFinder = () => {

    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext();
    const [open, setOpen] = useState(false);
    const [filteredRoutes, setFilteredRoutes] = useState(mapState.polylines)
    const [selectedRouteTypes, setSelectedRouteTypes] = useState(MapHelpers.routeEnumFlagsToArray(mapState.filter.type))
    const { isMobile } = useWindowContext();
    const navigate = useNavigate();
    const location = useLocation();

    const setUpToggleRouteFinder = () => {
        const toggleRouteFinder = (data: { open: boolean }) => {
            setOpen(data.open)
            ReactGA.event({
                category: 'Toggle',
                action: 'Route Finder',
                label: data.open ? 'Open' : 'Closed'
            });
        };

        routeFinderEventEmitter.off(EventNames.toggleRouteFinder, toggleRouteFinder);
        routeFinderEventEmitter.on(EventNames.toggleRouteFinder, toggleRouteFinder);
    }

    useEffect(() => {
        setUpToggleRouteFinder();
        let routes = MapHelpers.applyFilter(mapState.polylines, mapState.filter);
        routes = MapHelpers.applyPendingPolylineFilter(routes, appState.account);
        routes = MapHelpers.applySorting(routes, mapState.filter)
        setFilteredRoutes(routes);
        setSelectedRouteTypes(MapHelpers.routeEnumFlagsToArray(mapState.filter.type))
    }, [mapState])

    const toggleDrawer = () => {
        if (mapState.interactionMode === MapInteractionMode.DrawRoute) {
            MapActions.cancelDrawRouteMode();
        }
        updateMapState({
            ...mapState,
            filter: new RouteFilter()
        })
        const isOpen = !open;
        ReactGA.event({
            category: 'Toggle',
            action: 'Route Finder',
            label: isOpen ? 'Open' : 'Closed'
        });
        setOpen(isOpen);
    };

    const onRowClicked = (r: ISearchResultTableItem) => {
        ReactGA.event({
            category: 'Click',
            action: 'Route Finder - Routes List - Clicked Route',
            label: `${r.id}: ${r.name}`
        });
        updateMapState({
            ...mapState,
            clickedPolylineId: r.id
        })
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('q');
        queryParams.set('routeId', `${r.id}`);
        navigate(`${location.pathname}?${queryParams.toString()}`);
    }


    const routeCheckboxeOptions: { label: JSX.Element, value: PolylineType }[] =
        MapHelpers.getRouteTypesEnumArray()
            .map((val, i) => {
                return {
                    label: <Tooltip title={MapHelpers.getRouteTypeLabel(val)}>
                        <Tag key={`route-finder-tag-${i}`} color={ThemeHelpers.getRouteColour(val)}
                            style={{
                                // height: '30px',
                                // width: '40px',
                                textAlign: 'center',
                                alignContent: 'space-evenly'
                            }}>
                            {MapHelpers.getRouteTypeIcon(val)}
                        </Tag>
                    </Tooltip>,
                    value: val
                }
            })

    const routeTypesCheckChanged = (checkedValues: PolylineType[]): void => {
        setSelectedRouteTypes(checkedValues);
        const selectedItem = checkedValues.reduce((acc, flag) => acc | flag, PolylineType.Unknown);
        updateMapState({
            ...mapState,
            filter: {
                ...mapState.filter,
                type: selectedItem
            }
        })
        ReactGA.event({
            category: 'Route Finder',
            action: 'Filter Types',
            label: checkedValues.map(x => PolylineType[x]).join(', ')
        });
    }

    const sortByOptions: { label: string, value: SortOption }[] = MapHelpers.getSortOptionsEnumArray()
        .map((val, i) => {
            return {
                label: MapHelpers.getSortOptionLabel(val),
                value: val
            }
        })

    const onSortByOptionChange = (value: SortOption, option: { label: string; value: SortOption; } | { label: string; value: SortOption; }[]): void => {
        updateMapState({
            ...mapState,
            filter: {
                ...mapState.filter,
                sort: value
            }
        })
        ReactGA.event({
            category: 'Route Finder',
            action: 'Sort By',
            label: SortOption[value]
        });
    }

    const onNameSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        updateMapState({
            ...mapState,
            filter: {
                ...mapState.filter,
                name: event.target.value
            }
        })
    }

    const onAddRouteclicked = () => {
        setOpen(false)
        MapActions.beginDrawRouteMode();
        ReactGA.event({
            category: 'Route Finder',
            action: 'Click',
            label: 'Add Route'
        });
    }

    const checkboxGroupStyle: React.CSSProperties = {
        padding: '5px',
        display: 'block',
        width: '100%',
        textAlign: 'left'
    }

    if (mapState.interactionMode === MapInteractionMode.DrawRoute) {
        return <></>
    }

    return <>
        <Drawer
            title={
                <Row>
                    <Col span={12}>
                        Route Finder
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            onClick={onAddRouteclicked}
                            icon={Icons.DrawRouteModeIcon}
                            type="primary"
                            size="small">
                            Add Route
                        </Button>
                    </Col>
                </Row>
            }
            placement={'right'}
            closable={true}
            mask={false}
            onClose={toggleDrawer}
            open={open}
            styles={UiHelpers.getRightMenuStyle(isMobile)}
        >
            <ConfigProvider theme={{
                token: {
                    colorPrimary: 'grey' //checkbox check colour
                }
            }}>
                <Checkbox.Group
                    style={checkboxGroupStyle}

                    options={routeCheckboxeOptions}
                    defaultValue={selectedRouteTypes}
                    value={MapHelpers.routeEnumFlagsToArray(mapState.filter.type)}
                    onChange={routeTypesCheckChanged}
                />
            </ConfigProvider>
            <Space.Compact direction="horizontal" className="full-width">
                <Input placeholder="Route names" defaultValue={mapState.filter.name} onChange={onNameSearchChange} />
                <Select value={mapState.filter.sort} defaultValue={mapState.filter.sort} options={sortByOptions} onChange={onSortByOptionChange} />
            </Space.Compact>

            <RoutesList showHeader forceVirtual onRowClicked={onRowClicked} routes={filteredRoutes} />
        </Drawer>
    </>
}

export default RouteFinder