import { Button, ConfigProvider, Space } from "antd";
import { MapActions } from "../helpers/MapActions";
import { Icons } from "../ui/Icons";
import { themeStyles } from "../../../constants/theme";

export const DrawRouteModeContextMenu = () => {


    return <Space.Compact direction={'vertical'}>
        <Button
            icon={Icons.UndoIcon}
            onClick={(e) => { e.stopPropagation(); MapActions.undoClicked() }}
            size="middle"
            type="default">
            Undo
        </Button>
        <ConfigProvider theme={{
            token: {
                colorPrimary: themeStyles.colorError,
            }
        }}>
            <Button
                icon={Icons.CancelIcon}
                onClick={(e) => { e.stopPropagation(); MapActions.cancelDrawRouteMode() }}
                size="middle"
                type="primary">
                Cancel
            </Button>
        </ConfigProvider>

    </Space.Compact>
}

export default DrawRouteModeContextMenu