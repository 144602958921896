import { Badge, Button, Table, TableColumnsType, Tag, Tooltip } from "antd"
import { MapHelpers } from "../helpers/MapHelpers"
import { IPolylineModel, PolylineType } from "../../../models/map/PolylineModel"
import { ThemeHelpers } from "../../../helpers/ThemeHelpers"
import { Icons } from "./Icons"
import { useWindowContext } from "../../../contexts/WindowContext"
import { useMapContext } from "../../../contexts/MapContext"
import { themeStyles } from "../../../constants/theme"
import { useAppContext } from "../../../contexts/AppContext"
import '../../../css/table.css'
import { routeFinderItemClickedEventEmitter, EventNames } from "../helpers/EventEmitter"
import { IRouteFilter, SortOption } from "../../../models/map/RouteFilter"
import ReactGA from 'react-ga4'

export interface ISearchResultTableItem {
    id: number
    key: string
    name: string
    polylineType: PolylineType,
    distanceToSearchLocation: string | JSX.Element
    length: string
    imageCount: number
    pending: boolean
}


interface IProps {
    onRowClicked: (r: ISearchResultTableItem) => void
    routes: IPolylineModel[]
    showHeader: boolean
    forceVirtual?: boolean
    onAddPhoto?: boolean
}

export const RoutesList = (props: IProps) => {
    const { isMobile, viewport } = useWindowContext();
    const { mapState, updateMapState } = useMapContext();
    const { appState } = useAppContext();

    const distTooltipText = () => {
        if (mapState.filter.sort === SortOption.NearMapCentre) {
            return "Distance from centre of map view"
        }
        if (mapState.filter.sort === SortOption.SelectedLocation) {
            if (mapState.filter.searchPoint == null) {
                return `${isMobile ? 'Long press' : 'Right click'} map and select 'Route Finder' to set search point`;
            }
            else {
                return 'Distance from search point'
            }
        }
    }

    const getTooltipText = (item: ISearchResultTableItem) => {
        if (item.pending)
            return 'Submission Pending'

        if (item.imageCount === 1)
            return `1 photo`

        if (item.imageCount > 1)
            return `${item.imageCount} photos`

        return ''
    }

    const columns: TableColumnsType<ISearchResultTableItem> = [
        {
            title: 'Type',
            align: 'center',
            width: props.onAddPhoto ? 150 : '15%',
            render: (p: ISearchResultTableItem) => <>
                <div style={{ textAlign: 'center' }}>
                    <Tooltip title={getTooltipText(p)}>
                        <Badge count={p.pending ? '!' : 0} size="small" offset={[-22, 11]} color={ThemeHelpers.getRouteColour(p.polylineType)}>
                            <Badge count={p.imageCount} color={themeStyles.colorPrimary} size="small" offset={[-10, 18]}>
                                <Tag color={ThemeHelpers.getRouteColour(p.polylineType)}>
                                    {MapHelpers.getRouteTypeIcon(p.polylineType)}
                                </Tag>
                            </Badge>
                        </Badge>
                    </Tooltip>
                    {
                        mapState.addingImage?.routeId === p.id &&
                        <span style={{ color: 'green', marginLeft: '5px' }}>
                            <span>
                                {Icons.CheckMarkIcon}
                            </span>
                            <span> Selected</span>
                        </span>
                    }
                </div>
            </>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: props.onAddPhoto ? 'auto' : '35%'
        },
        {
            title: 'Len.',
            dataIndex: 'length',
            width: '15%',
            hidden: props.onAddPhoto === true
        },
        {
            title: <>

                <Tooltip title={distTooltipText()}>
                    Dist. {Icons.HelpIcon}
                </Tooltip>
            </>,
            width: '20%',
            dataIndex: 'distanceToSearchLocation',
            align: 'center',
            hidden: props.onAddPhoto === true
        },
        {
            title: 'Locate',
            dataIndex: 'id',
            width: '15%',
            align: 'center',
            hidden: props.onAddPhoto === true,
            render(id) {
                return <Button
                    onClick={(e) => { e.stopPropagation(); onLocateRouteClicked(id) }}
                    type="default"
                    size="small"
                    icon={Icons.LocateRouteCrosshairs}
                />
            }
        },
    ];

    const getSearchPointdistance = (r: IPolylineModel, filter: IRouteFilter) => {
        if (mapState.filter.sort === SortOption.NearMapCentre && filter.mapCentre) {
            return MapHelpers.getDistanceFromPoint(r, filter.mapCentre)
        }
        if (mapState.filter.sort === SortOption.SelectedLocation && filter.searchPoint) {
            return MapHelpers.getDistanceFromPoint(r, filter.searchPoint)
        }
        return <span style={{ color: 'grey' }}> ? </span>
    }

    const data: ISearchResultTableItem[] = props.routes.map((r, i) => {
        return {
            id: r.id,
            key: `route-${r.id}`,
            name: r.name.length > 0 ? r.name : ' - ',
            polylineType: r.polylineType,
            distanceToSearchLocation: getSearchPointdistance(r, mapState.filter),
            length: MapHelpers.getPolylineLength(r),
            imageCount: r.images.length,
            pending: r.pending
        }
    })

    const onLocateRouteClicked = (id: number) => {
        const route = mapState.polylines.filter(x => x.id === id)[0]
        if (!route)
            return;
        routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: id })
        ReactGA.event({
            category: 'Route Finder',
            action: 'Clicked Locate',
            label: `${route.id}: ${route.name}`
        });
    }

    const virtual = isMobile || props.forceVirtual === true;

    return <Table
        onRow={(record, i) => {
            return {
                onClick: () => props.onRowClicked(record)
            };
        }}
        className={isMobile ? 'drawer-menu-table-mobile' : ''}
        rowClassName={'hover-pointer'}
        showHeader={props.showHeader}
        virtual={virtual}
        columns={columns}
        dataSource={data}
        pagination={!virtual ? { pageSize: 50 } : false}
        scroll={{ y: isMobile ? (35 * 4) : viewport.h - 250 }}
        size="small" />
}

export default RoutesList