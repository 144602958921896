import Map from "../components/map/Map";
import { useLocation } from 'react-router-dom';
import { routeFinderEventEmitter, EventNames, routeFinderItemClickedEventEmitter } from "../components/map/helpers/EventEmitter";
import { useMapContext } from "../contexts/MapContext";
import { MapInteractionMode } from "../models/map/MapState";
import { isNumberObject } from "util/types";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4'

function MapPage() {
    ReactGA.send({ hitType: "pageview", page: "/map", title: "Map" });

    const { mapState, updateMapState } = useMapContext();
    const location = useLocation();
    // Parse the query string from the location object

    const setRouteLocation = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const routeId = queryParams.get('routeId')
        updateMapState({
            ...mapState,
            clickedPolylineId: Number(routeId)
        })
        if (routeId) {
            setTimeout(() => {
                routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: Number(routeId) })
            }, 500)
        }
    }

    const openRouteFinder = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const q = queryParams.get('q')
        if (q === 'route-finder') {
            routeFinderEventEmitter.emit(EventNames.toggleRouteFinder, { open: true })
        }
    }

    useEffect(() => {
        setRouteLocation();
        openRouteFinder();
    }, [])

    return <Map />
}

export default MapPage;