import { themeStyles } from "../constants/theme";
import { PolylineType } from "../models/map/PolylineModel";

export class ThemeHelpers {
    static getRouteColour(type: PolylineType) {
        switch (type) {
            case PolylineType.OffRoad: return themeStyles.routeColours.offRoad;
            case PolylineType.Hiking: return themeStyles.routeColours.hiking;
            case PolylineType.MountainBike: return themeStyles.routeColours.mountainBike;
            case PolylineType.DirtBike: return themeStyles.routeColours.dirtbike;
            default: return 'black';
        }
    }
}