import { Marker, Polyline, Tooltip, useMap, useMapEvents, } from "react-leaflet";
import { useMapContext } from "../../../contexts/MapContext";
import { DivIcon, LatLng, LatLngExpression, PathOptions } from "leaflet";
import { useEffect, useReducer, useState } from "react";
import { IPolylineModel, PolylineModel, PolylineType } from "../../../models/map/PolylineModel";
import { MapHelpers } from "../helpers/MapHelpers";
import { MapInteractionMode } from "../../../models/map/MapState";
import '../../../css/map.css'
import { themeConstants, themeStyles } from "../../../constants/theme";
import { MapLayer } from "../helpers/MapLayer";
import { useAppContext } from "../../../contexts/AppContext";
import { MapLayerType } from "../../../hooks/useCustomCRSFor27700Projection";
import { MapActions } from "../helpers/MapActions";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4'

export const RoutePolylines = () => {

    const { mapState, updateMapState } = useMapContext();
    const { appState } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [zoom, setZoom] = useState(mapState.selectedMap.crsOptions.zoom)

    const [polyLineData, setPolylineData] = useState<{
        positions: LatLngExpression[];
        options: PathOptions;
        name: string,
        id: number
    }[]>([])

    const map = useMap();

    useEffect(() => {
        setTimeout(() => {
            updatePolylineData();
        }, 1);
    }, [mapState, setPolylineData, zoom])

    useMapEvents({
        zoomend() {
            setZoom(map.getZoom())
            setTimeout(() => {
                updatePolylineData();
            }, 1);
        },
        moveend() {
            setZoom(map.getZoom())
            setTimeout(() => {
                updatePolylineData();
            }, 1);
        }
    })

    const withinZoomLimit = () => {
        return map.getZoom() >= MapLayer.routesRenderLimit;
    }

    const shouldPolylineRender = (p: IPolylineModel) => {

        try {
            if (mapState.interactionMode === MapInteractionMode.DrawRoute) {
                return false;
            }

            if (!withinZoomLimit()) {
                // render markers instead
                return false;
            }

            const allowedRadius = MapLayer.routesRenderRadius;
            const bounds = map.getBounds();
            const centre = bounds.getCenter();

            return p.points.filter(point => centre.distanceTo(point) <= allowedRadius).length > 0
        }
        catch (error) {
            return false;
        }

    }


    const updatePolylineData = () => {
        let filteredRoutes = mapState.polylines;
        filteredRoutes = filteredRoutes.filter(r => shouldPolylineRender(r) && r.id !== mapState.clickedPolylineId && r.id !== mapState.addingImage?.routeId)
        filteredRoutes = MapHelpers.applyPendingPolylineFilter(filteredRoutes, appState.account);
        filteredRoutes = MapHelpers.applyFilter(filteredRoutes, mapState.filter);

        const _polyLineData = filteredRoutes.map((p: IPolylineModel, i) => {
            const positions: LatLngExpression[] = p.points.map((p) => [p.lat, p.lng] as LatLngExpression)

            let options: PathOptions = {};

            options = MapHelpers.getPolylinePathOptions(p);

            return {
                id: p.id,
                positions: positions,
                name: p.name,
                options: {
                    ...options
                }
            }
        })
        setPolylineData(_polyLineData)
    }

    const onPolylineClicked = (id: number, pos: LatLng) => {
        const clickedPolyline = mapState.polylines.filter(p => p.id === id)[0]
        if (!clickedPolyline)
            return;

        if (mapState.interactionMode === MapInteractionMode.Normal) {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('routeId', `${id}`);
            queryParams.delete('q');
            navigate(`${location.pathname}?${queryParams.toString()}`);

            updateMapState({
                ...mapState,
                clickedPolylineId: id,
            })

        }
        if (mapState.interactionMode === MapInteractionMode.AddImage) {
            if (!mapState.addingImage)
                return;
            updateMapState({
                ...mapState,
                addingImage: {
                    ...mapState.addingImage,
                    routeId: id
                }
            })
        }

        ReactGA.event({
            category: 'Map Interaction',
            action: `Clicked Polyline (${MapInteractionMode[mapState.interactionMode]})`,
            label: `${clickedPolyline.id}: ${clickedPolyline.name}`
        });
    }

    return <>
        {withinZoomLimit() &&
            polyLineData.map((p, i) => {

                return <Polyline eventHandlers={{
                    click(e) {
                        onPolylineClicked(p.id, e.latlng)
                    }
                }}
                    key={`polyline-${i}-${p.options.className}-${map.getZoom()}`}
                    pathOptions={p.options}
                    positions={p.positions} >
                    <Tooltip sticky>
                        {p.name}
                    </Tooltip>
                </Polyline>
            })}
    </>
}

export default RoutePolylines