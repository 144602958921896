export interface IApiResult<T> {
    data?: T;
    isSuccess: boolean;
    errorMessage: string;
    token: string
}

export class ApiResult<T> implements IApiResult<T> {

    token: string = '';

    constructor(
        public isSuccess: boolean = true,
        public errorMessage: string = '',
        public data?: T) {
    }
}