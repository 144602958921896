import { Drawer, Row, Col, Button, Descriptions, Image, Space, Checkbox, Tooltip, Badge, Switch, App } from "antd"
import { UiHelpers } from "../../../helpers/UiHelpers"
import { routeFinderItemClickedEventEmitter, EventNames } from "../helpers/EventEmitter"
import { MapHelpers } from "../helpers/MapHelpers"
import { Icons } from "./Icons"
import { IPolylineModel } from "../../../models/map/PolylineModel"
import { DescriptionsItemType } from "antd/es/descriptions"
import { PolylineImage } from "../../../models/map/PolylineImage"
import { useMap } from "react-leaflet"
import { MapLayer } from "../helpers/MapLayer"
import { themeStyles } from "../../../constants/theme"
import { useWindowContext } from "../../../contexts/WindowContext"
import { DateHelpers } from "../../../helpers/DateHelpers"
import { useAppContext } from "../../../contexts/AppContext"
import { UserType } from "../../../models/general/User"
import { useMapContext } from "../../../contexts/MapContext"
import { setMainImage } from "../../../client/imageClient"
import ReactGA from 'react-ga4'

interface IProps {
    isMobile: boolean
    polyine: IPolylineModel
    onClose: () => void
    drawerItems: DescriptionsItemType[]
    showImageMarkers: boolean
    createImagePreview: (img: PolylineImage, h: string, w: string, i: number) => JSX.Element
    setShowImageMarkers: React.Dispatch<React.SetStateAction<boolean>>
}

export const RouteDetails = (props: IProps) => {
    const p = props.polyine;
    const map = useMap();
    const { isMobile } = useWindowContext();
    const { appState, updateAppState } = useAppContext();
    const { mapState, updateMapState } = useMapContext();
    const app = App.useApp();

    const onSetMainImageClicked = async (image: PolylineImage) => {
        updateAppState({
            ...appState,
            isLoading: true
        })
        const result = await setMainImage(image.id);
        if (result.isSuccess) {
            let polylines = [...mapState.polylines];
            let p = polylines.find(x => x.id === image.routeId)
            if (p) {
                if (result.data) {
                    let index = polylines.indexOf(p)
                    p.images = result.data;
                    polylines.splice(index, 0, p);
                    updateMapState({
                        ...mapState,
                        polylines: polylines
                    })
                    app.notification.info({
                        message: 'Main image set',
                        placement: 'top'
                    })
                }
            }
        }
        else {
            app.notification.error({
                message: 'Failed',
                placement: 'top'
            })
        }
        updateAppState({
            ...appState,
            isLoading: false
        })
    }

    const images = p.images.filter(x => !x.pending)

    return <Drawer
        title={<Row justify={"space-between"} align={"middle"}>
            <Col>
                <Badge count={p.pending ? 'Pending' : 0} size="small" offset={[-125, 32]} color={themeStyles.colorWarn}>
                    <div style={{ fontSize: 20, fontWeight: "bold", color: 'white' }}>
                        {MapHelpers.getRouteTypeIcon(p.polylineType)} Route Details
                    </div>
                </Badge>
            </Col>
            <Col>
                <Tooltip title="Locate">
                    <Button
                        type="default"
                        onClick={() => {
                            routeFinderItemClickedEventEmitter.emit(EventNames.flyToRoute, { routeId: p.id });
                            ReactGA.event({
                                category: 'Route Details',
                                action: 'Clicked Locate',
                                label: `${p.id}: ${p.name}`
                            });
                        }}
                        icon={Icons.LocateRouteCrosshairs} />
                </Tooltip>
            </Col>
        </Row>}
        placement={'right'}
        open
        closable={true}
        mask={false}
        onClose={props.onClose}
        styles={{
            ...UiHelpers.getRightMenuStyle(props.isMobile),
            body: {
                padding: 0,
                width: 'auto',
                height: props.isMobile ? 'calc(50vh - 45px)' : 'auto',
                display: 'flex',
                flexDirection: 'column'
            },
            header: {
                padding: isMobile ? '3px 12px' : '16px 24px',
                backgroundColor: MapHelpers.getRouteColour(p.polylineType)
            },
        }}
    >
        <div style={{ overflowY: 'auto' }}>
            <Descriptions className={`route-description ${isMobile ? 'route-description-mobile' : ''}`} bordered size="small" items={props.drawerItems} column={1} />
            {
                images.length > 0 &&
                <>
                    {
                        !MapLayer.outsideRouteRenderLimit(map.getZoom()) &&
                        <Space direction="vertical" style={{ margin: 10 }}>
                            <Checkbox checked={props.showImageMarkers} onChange={() => props.setShowImageMarkers(!props.showImageMarkers)}>
                                Show images on map
                            </Checkbox>
                        </Space>
                    }
                    <div style={{
                        flex: 1,
                        paddingBottom: '50px'
                    }}>
                        {
                            images.length > 0 && <Row align={'middle'} justify={'space-around'}>
                                {
                                    images.map((img, i) => <Col key={`img-preview-${img.id}`}>
                                        <div style={{
                                            padding: 2,
                                            boxShadow: '0 0 15px 1px grey',
                                            marginTop: 5,
                                            textAlign: 'center'
                                        }}>
                                            {
                                                isMobile
                                                    ? props.createImagePreview(img, '100px', '100px', i)
                                                    : props.createImagePreview(img, '100px', '100px', i)
                                            }
                                            {DateHelpers.formateDateMMMYYYY(new Date(img.createdDate as Date))}
                                            {
                                                appState.account.userType === UserType.Admin &&
                                                <>
                                                    <div>
                                                        <Switch value={img.isMainImage} onChange={() => onSetMainImageClicked(img)} />
                                                        {img.isMainImage ? ' Main' : ''}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Col>)
                                }
                            </Row>
                        }
                    </div>
                </>
            }
        </div>
    </Drawer >
}

export default RouteDetails