import { PolylineImage } from "../map/PolylineImage";

export enum UserType {
    System,
    Admin,
    Public
}

export class UserModel implements IUserModel {
    constructor() {
        this.isLoggedIn = false;
    }
    id: string = '';
    name: string = '';
    email: string = ''
    userType: UserType = UserType.Public;
    routeIDs: number[] = [];
    images: PolylineImage[] = [];
    isLoggedIn: boolean = false;
    token: string = '';
}

export const LoggedOutUser = new UserModel();

export interface IUserModel {
    id: string;
    name: string;
    email: string;
    userType: UserType;
    routeIDs: number[];
    images: PolylineImage[],
    isLoggedIn: boolean;
    token: string;
}