
export interface IMonthObject {
    month: number;
    name: string;
}

export class DateHelpers {
    static getDates = () => {
        return Array.from({ length: 31 }, (_, index) => index + 1);
    }

    static getMonths = () => {
        const months: IMonthObject[] = [];
        const monthNames: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        for (let i = 0; i < 12; i++) {
            months.push({ month: i, name: monthNames[i] });
        }
        return months;
    }

    static getYears = (startYear: number = new Date().getFullYear()) => {
        const currentYear = new Date().getFullYear();
        const futureYears = 20;

        const years: number[] = [];
        for (let i = startYear; i <= currentYear + futureYears; i++) {
            years.push(i);
        }

        return years;
    }

    static formateDateMMMYYYY = (date: Date): string => {
        return new Intl.DateTimeFormat('en-GB', { month: 'short', year: 'numeric' }).format(date);
    }

    static formatDateDDMMMYYYY = (date: Date): string => {
        return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(date);
    }
}