import { LatLng } from "leaflet"
import { PolylineImage } from "./PolylineImage"


export enum PolylineType {
    Unknown = 1,
    OffRoad = 2,
    Hiking = 4,
    MountainBike = 8,
    DirtBike = 16
}

export enum RoadType {
    Unknown,
    PublicGreenLane,
    Permissive,
    Private
}

export enum TRO_Type {
    Permanent,
    Temporary
}


export interface IPolylineModel {
    name: string
    points: L.LatLng[]
    notes: string
    id: number,
    polylineType: PolylineType
    roadType: RoadType | null
    images: PolylineImage[]
    pending: boolean
    createdDate: Date
    hasTRO: boolean;
    trO_Type: TRO_Type | null;
    trO_EndDate: Date | null;
    winterConditions: string;
    summerConditions: string;
}

export class PolylineModel implements IPolylineModel {

    name: string = ''
    points: LatLng[] = []
    id: number = 0
    notes: string = ''
    pathOptions: L.PathOptions = {}
    polylineType: PolylineType
    roadType: RoadType | null = null
    images: PolylineImage[] = []
    pending: boolean = false
    createdDate: Date = new Date()
    hasTRO: boolean = false;
    trO_Type: TRO_Type | null = null;
    trO_EndDate: Date | null = null;
    winterConditions: string = "";
    summerConditions: string = "";

    constructor(type: PolylineType) {
        this.polylineType = type;
    }

}