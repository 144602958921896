import { ApiResult, IApiResult } from "../models/api/apiResult";

export const userTokenCookieName = 'ormuktoken'

export function setCookie(name: string, value: string, expirationDays: number) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    const cookieValue = `${encodeURIComponent(name)}=${encodeURIComponent(value)};expires=${expirationDate.toUTCString()};path=/`;
    document.cookie = cookieValue;
}

export function getCookie(name: string): string | null {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }

    return null;
}

export function getUserTokenCookie() {
    return getCookie(userTokenCookieName)
}

export function handleApiResultToken<T>(apiResult: IApiResult<T>): IApiResult<T> {
    if (!apiResult.token || apiResult.token.length === 0) {
        return new ApiResult(false, "No data")
    }
    //console.log(apiResult.token)
    setCookie(userTokenCookieName, apiResult.token, 5);
    return apiResult
}