import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/map';
import Layout from './Layout';
import *  as Antd from 'antd'
import { MapContextProvider, useMapContext } from './contexts/MapContext';
import { WindowContextProvider } from './contexts/WindowContext';
import '../src/css/app.css'
import { AppContextProvider, useAppContext } from './contexts/AppContext';
import { MapActions } from './components/map/helpers/MapActions';
import { ConfigProvider } from 'antd';
import { themeStyles } from './constants/theme';
import MapPage from './pages/map';
import AboutPage from './pages/about';
import HelpPage from './pages/help';
import MySubmissions from './pages/my-submissions';
import Privacy from './pages/privacy';
import ReactGA from 'react-ga4'

function App() {
  ReactGA.initialize('G-YREYMHSNXE')
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: themeStyles.colorPrimary,
        colorError: themeStyles.colorError,
        colorWarning: themeStyles.colorWarn,
        colorSuccess: themeStyles.colorSuccess,
        colorInfo: themeStyles.colorInfo
      }
    }}>
      <AppContextProvider>
        <WindowContextProvider>
          <MapContextProvider>
            <Antd.App>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Layout />}>

                    {/* Redirect '/' to '/map' */}
                    <Route index element={<Navigate to="/map" />} />
                    <Route path="map" element={<MapPage />} />

                    <Route path='my-submissions' element={<MySubmissions />} />
                    <Route path='about' element={<AboutPage />} />
                    <Route path='help' element={<HelpPage />} />
                    <Route path='privacy' element={<Privacy />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </Antd.App>
          </MapContextProvider>
        </WindowContextProvider>
      </AppContextProvider>
    </ConfigProvider>
  );
}

export default App;
