import { LatLng } from "leaflet";
import { ICustomMapCrsOptions, MapLayerType, useCustomCRSFor27700Projection, useCustomCRSForEPSG3857Projection } from "../../../hooks/useCustomCRSFor27700Projection";
import { MapHelpers } from "./MapHelpers";

export interface IMapLayerConfig {
    name: string,
    url: string,
    icon: JSX.Element,
    crsOptions: ICustomMapCrsOptions
}

const osMaps = {
    light: 'Light_27700',
    os: 'Leisure_27700',
    outdoor: 'Outdoor_27700',
    road: 'Road_27700'
}

const mapBoxMap = {
    streets: 'streets-v12',
    light: 'light-v11',
    outdoors: 'outdoors-v12',
    satelliteStreeets: 'satellite-streets-v12'
}
export class MapLayer {

    static osMapApiKey = process.env.REACT_APP_OS_MAPS_KEY
    static mapBoxApiKey = process.env.REACT_APP_MAP_BOX_KEY

    /**Based on OS Explorer zoom levels */
    static baseMinZoom = 1;
    /**Based on OS Explorer zoom levels */
    static baseMaxZoom = 9;
    /**Based on OS Explorer zoom levels */
    static baseZoom = 0;
    /**Based on OS Explorer zoom levels */
    static baseRouteRenderLimit = 5;
    /**Based on OS Explorer zoom levels */
    static baseMarkerRenderLimit = 0;

    static EPSG3857_Upscale = 7

    static center_UK = new LatLng(53.95054598380019, -1.3526101292941974)
    static bounds_UK = [
        [45, -15],
        [60, 10]];
    static routesRenderLimit = 5; // max zoom out = 0, max zoom in = depends on map type, 
    static markersRenderLimit = 0;
    static routesRenderRadius = 40 * 1000 // km
    static routeMarkersRenderRadius = 300 * 1000; // km
    static addImageRoutesListProximity = 1000;// metres



    static UpdateMapLayerLimits(config: IMapLayerConfig) {
        switch (config.crsOptions.layerType) {
            case MapLayerType.EPSG27700:
                console.log('Updating map settings to EPSG27700');
                this.markersRenderLimit = this.baseMarkerRenderLimit;
                this.routesRenderLimit = this.baseRouteRenderLimit;
                break;

            case MapLayerType.EPSG3857:
                console.log('Updating map settings to EPSG3857');
                this.markersRenderLimit = this.baseMarkerRenderLimit + this.EPSG3857_Upscale;
                this.routesRenderLimit = this.baseRouteRenderLimit + this.EPSG3857_Upscale;
                break;

        }
    }

    static outsideRenderLimit(zoomLevel: number): boolean {
        return zoomLevel < MapLayer.markersRenderLimit
    }

    static outsideRouteRenderLimit(zoomLevel: number): boolean {
        return zoomLevel < MapLayer.routesRenderLimit
    }

    static hasApiKeys = () => {
        return this.osMapApiKey && this.mapBoxApiKey && this.osMapApiKey.length > 0 && this.mapBoxApiKey.length > 0;
    }

    static osMapUrl = () => {
        return `https://api.os.uk/maps/raster/v1/zxy/{layerName}/{z}/{x}/{y}.png?key=${MapLayer.osMapApiKey}`
    };
    static mapBoxUrl = () => {
        return `https://api.mapbox.com/styles/v1/mapbox/{layerName}/tiles/256/{z}/{x}/{y}?access_token=${MapLayer.mapBoxApiKey}`
    }

    static getMapOptions = (): IMapLayerConfig[] => {
        return [
            {
                name: 'Outdoor',
                icon: <></>,
                url: MapLayer.mapBoxUrl().replace('{layerName}', mapBoxMap.outdoors),
                crsOptions: useCustomCRSForEPSG3857Projection()
            },
            {
                name: 'OS Explorer',
                icon: <></>,
                url: MapLayer.osMapUrl().replace('{layerName}', osMaps.os),
                crsOptions: useCustomCRSFor27700Projection()
            },
            {
                name: 'Satellite',
                icon: <></>,
                url: MapLayer.mapBoxUrl().replace('{layerName}', mapBoxMap.satelliteStreeets),
                crsOptions: useCustomCRSForEPSG3857Projection()
            }
        ]
    }


}


