import { Drawer, Space, Button, Radio, ConfigProvider, Input, RadioChangeEvent, Tooltip, Col, Row, Select, Result, Checkbox, Switch, DatePicker, Divider } from "antd"
import { themeStyles } from "../../../constants/theme"
import { ThemeHelpers } from "../../../helpers/ThemeHelpers"
import { IPolylineModel, PolylineType, RoadType, TRO_Type } from "../../../models/map/PolylineModel"
import { MapActions } from "../helpers/MapActions"
import { MapHelpers } from "../helpers/MapHelpers"
import { Icons } from "./Icons"
import { useMapContext } from "../../../contexts/MapContext"
import React, { ChangeEvent, useEffect, useState } from "react"
import { mobileBreakPoint, useWindowContext } from "../../../contexts/WindowContext"
import { drawRouteDetailsEventEmitter, EventNames } from "../helpers/EventEmitter"
import { UiHelpers } from "../../../helpers/UiHelpers"
import { useAppContext } from "../../../contexts/AppContext"
import Login from "../../general/Login"
import TextArea from "antd/es/input/TextArea"
import { DateHelpers } from "../../../helpers/DateHelpers"

export const DrawRouteDetails = () => {

    const [open, setOpen] = useState(false);
    const { mapState, updateMapState } = useMapContext();
    const { appState, updateAppState } = useAppContext();
    const [troDay, setTroDay] = useState<number | null>(null);
    const [troMonth, setTroMonth] = useState<number | null>(null);
    const [troYear, setTroYear] = useState<number | null>(null);
    const { isMobile } = useWindowContext();

    const setUpToggleDrawRouteDetails = () => {
        const toggleDrawRouteDetails = (data: { open: boolean }) => {
            setOpen(data.open)
        };

        drawRouteDetailsEventEmitter.off(EventNames.toggleDrawRouteDetails, toggleDrawRouteDetails);
        drawRouteDetailsEventEmitter.on(EventNames.toggleDrawRouteDetails, toggleDrawRouteDetails);
    }

    useEffect(() => {
        setUpToggleDrawRouteDetails();
    }, [mapState])

    const onRouteNameChanged = (event: ChangeEvent<HTMLInputElement>): void => {
        let name = event.target.value;
        if (name.length > MapHelpers.maxRouteNameLength) {
            return;
        }

        let polyline = mapState.editingPolyline as IPolylineModel
        polyline.name = name;
        updateMapState({
            ...mapState,
            editingPolyline: polyline
        })
    }

    const onRouteTypeChanged = (e: RadioChangeEvent): void => {
        if (!e.target.value)
            return;

        let polyline = mapState.editingPolyline as IPolylineModel

        if (!polyline)
            return;

        let val = Number(e.target.value) as PolylineType;
        if (val !== PolylineType.OffRoad && val !== PolylineType.DirtBike) {
            polyline.roadType = null
        }
        polyline.polylineType = val;

        updateMapState({
            ...mapState,
            editingPolyline: polyline
        })
    }

    const onCloseDrawer = () => {
        MapActions.cancelDrawRouteMode();
    }

    const inputLabelStyle: React.CSSProperties = {
        fontWeight: 'bold'
    }

    const requiredStyle: React.CSSProperties = {
        fontSize: '12px',
        color: 'red',
        fontWeight: 'normal'
    }

    const routeOptionsCount = (Object.values(PolylineType).length / 2) - 1;
    // Divide by 2 because Object.values gives array of keys and values for each
    // Subtract 1 because 'Unknown' value not wanted here
    const headerColStyle: React.CSSProperties = {
        padding: '0 10px'
    }

    const onRoadAccessibilitySelected = (value: RoadType | null, option: { value: RoadType; label: string } | { value: RoadType; label: string }[]): void => {
        updateMapState({
            ...mapState,
            editingPolyline: {
                ...mapState.editingPolyline as IPolylineModel,
                roadType: value
            }
        })
    }

    const onTroOptionChanged = (checked: boolean, event: any): void => {
        updateMapState({
            ...mapState,
            editingPolyline: {
                ...mapState.editingPolyline as IPolylineModel,
                hasTRO: checked
            }
        })
    }

    const onTRO_TypeChanged = (e: RadioChangeEvent): void => {
        updateMapState({
            ...mapState,
            editingPolyline: {
                ...mapState.editingPolyline as IPolylineModel,
                trO_Type: e.target.value as TRO_Type
            }
        })
    }

    const onTro_EndDateChanged = (d: number | null, m: number | null, y: number | null) => {
        if (d !== null) {
            setTroDay(d)
        }
        if (m !== null) {
            setTroMonth(m)
        }
        if (y !== null) {
            setTroYear(y)
        }
        if (d !== null && m !== null && y !== null) {
            const troDate = new Date(y, m, d)
            updateMapState({
                ...mapState,
                editingPolyline: {
                    ...mapState.editingPolyline as IPolylineModel,
                    trO_EndDate: troDate
                }
            })
        }
    }

    const onGeneralConditionsChanged = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        updateMapState({
            ...mapState,
            editingPolyline: {
                ...mapState.editingPolyline as IPolylineModel,
                notes: event.target.value
            }
        })
    }

    const onWinterConditionsChanged = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        updateMapState({
            ...mapState,
            editingPolyline: {
                ...mapState.editingPolyline as IPolylineModel,
                winterConditions: event.target.value
            }
        })
    }

    const onSummerConditionsChanged = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        updateMapState({
            ...mapState,
            editingPolyline: {
                ...mapState.editingPolyline as IPolylineModel,
                summerConditions: event.target.value
            }
        })
    }

    const e = mapState.editingPolyline;

    if (!e) {
        return <></>
    }

    return <Drawer
        destroyOnClose
        className={isMobile ? 'draw-route-drawer-mobile' : ''}
        title={appState.account.isLoggedIn &&
            <Row >
                <Col span={8} push={8} style={headerColStyle}>
                    <Button
                        className="full-width"
                        size="middle"
                        icon={Icons.UndoIcon}
                        onClick={MapActions.undoClicked}
                        type="default">
                        Undo
                    </Button>
                </Col>
                <Col span={8} push={8} style={headerColStyle}>
                    <Button
                        className="full-width"
                        onClick={MapActions.finishDrawRouteMode}
                        size="middle"
                        icon={Icons.FinishDrawRouteIcon}
                        type="primary">
                        {isMobile ? 'Save' : 'Save'}
                    </Button>
                </Col>
            </Row>}
        placement={'bottom'}
        onClose={onCloseDrawer}
        mask={false}
        open={open}
        styles={{
            ...UiHelpers.getBottomMenuStyle(isMobile, mobileBreakPoint),
            header: {
                padding: '7px 15px'
            },
            wrapper: {
                boxShadow: isMobile ? 'none' : '0 0 15px 1px grey',
                bottom: isMobile ? '-50px' : '-50px',
                width: isMobile ? 'auto' : mobileBreakPoint,
                margin: 'auto',
                opacity: UiHelpers.drawerOpacity,
                height: isMobile ? '300px' : '350px',
                borderTop: `solid 4px ${themeStyles.colorPrimary}`,
                marginBottom: '40px'
            },
            body: {
                padding: isMobile ? 0 : '20px',
                width: 'auto',
                height: 'auto'
            },
            content: {
                minHeight: '100%',
                paddingBottom: '25px'
            }
        }}>
        {
            !appState.account.isLoggedIn
                ? <Result
                    status="warning"
                    title="Not Logged In"
                    icon={null}
                    subTitle="You need to have an account to add routes"
                    extra={<Login />}
                />
                : <>

                    <Space direction="vertical" style={{
                        display: 'flex',
                        padding: '10px',
                        height: '100%'
                    }}>
                        <div style={inputLabelStyle}>
                            Route Name
                            {e?.name?.length === 0 && <span style={requiredStyle}> (required)</span>}
                        </div>
                        <Input count={{
                            show: true,
                            max: MapHelpers.maxRouteNameLength,
                        }} onChange={onRouteNameChanged}
                            value={e.name}
                            addonAfter={MapHelpers.getPolylineLength(e)} />
                        <div style={inputLabelStyle}>
                            Route Type
                            {e?.polylineType === PolylineType.Unknown && <span style={requiredStyle}> (required)</span>}
                        </div>

                        <Space.Compact direction="horizontal" block>
                            <Radio.Group className="radio-group-stretched"
                                onChange={onRouteTypeChanged}
                                buttonStyle="solid"
                                defaultValue={e?.polylineType}
                                size="middle">
                                {
                                    MapHelpers.getRouteTypesEnumArray()
                                        .map((type, i) => {
                                            const radio = <ConfigProvider key={`route-configs-${i}`}
                                                theme={{
                                                    token: {
                                                        colorPrimary: ThemeHelpers.getRouteColour(type)
                                                    },
                                                    components: {
                                                        Radio: {
                                                            buttonColor: ThemeHelpers.getRouteColour(type),
                                                            buttonSolidCheckedActiveBg: ThemeHelpers.getRouteColour(type),
                                                            buttonSolidCheckedBg: ThemeHelpers.getRouteColour(type),
                                                            buttonSolidCheckedColor: 'white'
                                                        },
                                                    },
                                                }}
                                            >
                                                <Tooltip title={MapHelpers.getRouteTypeLabel(type)}>
                                                    <Radio.Button key={`route-types-${i}`} value={type}
                                                        style={{
                                                            width: `calc(100% / ${routeOptionsCount})`,
                                                            textAlign: 'center',
                                                            fontSize: '20px'
                                                        }}>

                                                        {Icons.getRouteTypeIcon(type)}

                                                    </Radio.Button>
                                                </Tooltip>
                                            </ConfigProvider>
                                            return radio
                                        })
                                }
                            </Radio.Group>
                        </Space.Compact>
                        {
                            (e?.polylineType === PolylineType.OffRoad ||
                                e?.polylineType === PolylineType.DirtBike) &&
                            <Space direction="vertical" className="full-width">
                                <div style={inputLabelStyle}>
                                    Accessibility
                                    {e.roadType === null && <span style={requiredStyle}> (required)</span>}
                                </div>
                                <Select
                                    style={{
                                        width: isMobile ? '100%' : '100%'
                                    }}
                                    defaultValue={e.roadType}
                                    onChange={onRoadAccessibilitySelected}
                                    options={[
                                        { value: RoadType.PublicGreenLane, label: MapHelpers.getRoadTypeLabel(RoadType.PublicGreenLane) },
                                        { value: RoadType.Permissive, label: MapHelpers.getRoadTypeLabel(RoadType.Permissive) },
                                        { value: RoadType.Private, label: MapHelpers.getRoadTypeLabel(RoadType.Private) },
                                        { value: RoadType.Unknown, label: MapHelpers.getRoadTypeLabel(RoadType.Unknown) }
                                    ]} />

                                <div>
                                    <div style={inputLabelStyle}>
                                        <span>TRO </span>
                                        <Tooltip title={MapHelpers.TRO_Toolip}>
                                            {Icons.HelpIcon}
                                        </Tooltip>
                                    </div>
                                    <Switch
                                        unCheckedChildren={"No TRO"}
                                        checkedChildren={"Has TRO"}
                                        defaultValue={false}
                                        value={e.hasTRO}
                                        onChange={onTroOptionChanged} />
                                </div>
                                {
                                    e.hasTRO &&
                                    <>
                                        <>
                                            <div style={inputLabelStyle}>
                                                TRO Type
                                                {e.trO_Type === null && <span style={requiredStyle}> (required)</span>}
                                            </div>
                                            <Radio.Group defaultValue={-1} value={e.trO_Type} onChange={onTRO_TypeChanged}>
                                                <Radio value={TRO_Type.Temporary}>Temporary</Radio>
                                                <Radio value={TRO_Type.Permanent}>Permanent</Radio>
                                            </Radio.Group>
                                        </>
                                        {
                                            e.trO_Type === TRO_Type.Temporary &&
                                            <>
                                                <div style={inputLabelStyle}>
                                                    TRO Ends
                                                    {e.trO_Type === TRO_Type.Temporary && e.trO_EndDate === null
                                                        && <span style={requiredStyle}> (required)</span>}
                                                </div>
                                                <Space.Compact>
                                                    <Select onChange={(e) => onTro_EndDateChanged(e, troMonth, troYear)}
                                                        placeholder={'Date'}
                                                        defaultValue={e.trO_EndDate?.getDate()}
                                                        options={DateHelpers.getDates().map((d, i) => {
                                                            return {
                                                                value: d,
                                                                label: `${d}`
                                                            }
                                                        })} />
                                                    <Select onChange={(e) => onTro_EndDateChanged(troDay, e, troYear)}
                                                        placeholder={'Month'}
                                                        defaultValue={e.trO_EndDate?.getMonth()}
                                                        options={DateHelpers.getMonths().map((m, i) => {
                                                            return {
                                                                value: m.month,
                                                                label: m.name
                                                            }
                                                        })} />
                                                    <Select onChange={(e) => onTro_EndDateChanged(troDay, troMonth, e)}
                                                        placeholder={'Year'}
                                                        defaultValue={e.trO_EndDate?.getFullYear()}
                                                        options={DateHelpers.getYears().map((y, i) => {
                                                            return {
                                                                value: y,
                                                                label: `${y}`
                                                            }
                                                        })} />
                                                </Space.Compact>
                                            </>
                                        }
                                    </>
                                }

                            </Space>
                        }

                        <div style={inputLabelStyle}>
                            Notes
                        </div>
                        <TextArea value={e.notes} rows={2} onChange={onGeneralConditionsChanged} />

                        <div style={inputLabelStyle}>
                            Winter Conditions
                        </div>
                        <TextArea value={e.winterConditions} rows={2} onChange={onWinterConditionsChanged} />

                        <div style={inputLabelStyle}>
                            Summer Conditions
                        </div>
                        <TextArea value={e.summerConditions} rows={2} onChange={onSummerConditionsChanged} />

                        <Divider />
                        <Row >
                            <Col span={12} style={headerColStyle}>
                                <Button
                                    className="full-width"
                                    size="middle"
                                    icon={Icons.CancelIcon}
                                    onClick={MapActions.cancelDrawRouteMode}
                                    type="default">
                                    Cancel
                                </Button>
                            </Col>
                            <Col span={12} style={headerColStyle}>
                                <Button
                                    className="full-width"
                                    onClick={MapActions.finishDrawRouteMode}
                                    size="middle"
                                    icon={Icons.FinishDrawRouteIcon}
                                    type="primary">
                                    {isMobile ? 'Save' : 'Save'}
                                </Button>
                            </Col>
                        </Row>

                        <div style={{ height: '50px' }}>
                            {/* HACK to ensure there's empty space at bottom of form. 
                                        Margin/padding was a fuckaround thanks to antd items.
                                    */}
                        </div>
                    </Space>
                </>
        }

    </Drawer>
}

export default DrawRouteDetails