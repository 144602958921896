import { MapContainer, TileLayer } from "react-leaflet";
import MapControls from "./mapControlMenus/MapControls";
import { MapContextMenu } from "./contextMenus/MapContextMenu";
import DrawnRoute from "./features/DrawnRoute";
import ClickCircle from "./features/ClickCircle";
import DrawnRouteMarkers from "./features/DrawnRouteMarkers";
import { useEffect, useState } from "react";
import { useMapContext } from "../../contexts/MapContext";
import { MapInteractionMode } from "../../models/map/MapState";
import { themeStyles } from "../../constants/theme";
import 'leaflet/dist/leaflet.css';
import '../../css/map.css'
import { RoutePolylines } from "./features/RoutePolylines";
import SearchResults from "./ui/SearchResults";
import MapUiEventHandler from "./helpers/MapUiEventHandler";
import RouteFinder from "./ui/RouteFinder";
import ZoomWarning from "./ui/ZoomWarning";
import DrawRouteDetails from "./ui/DrawRouteDetails";
import MyLocationBtn from "./ui/MyLocationBtn";
import AddImage from "./ui/AddImage";
import AddImagePopup from "./features/AddImagePopup";
import RouteMarkers from "./features/RouteMarkers";
import SearchPointPopup from "./features/SearchPointPopup";
import ClickedPolylineFeatures from "./features/ClickedPolylineFeatures";
import { useAppContext } from "../../contexts/AppContext";
import L from "leaflet";
import { IMapLayerConfig, MapLayer } from "./helpers/MapLayer";
import MapLayersBtn from "./ui/MapLayersBtn";
import ClickedPolyline from "./features/ClickedPolyline";



const Map = () => {
    const { mapState } = useMapContext();
    const { appState } = useAppContext();
    const [tileLayerOpacity, setTileLayerOpacity] = useState(1);
    const [selectedMap, setSelectedMap] = useState<IMapLayerConfig>(mapState.selectedMap)

    const borderWidth = 5;

    useEffect(() => {
        const getCursorType = () => {
            switch (mapState.interactionMode) {
                case MapInteractionMode.DrawRoute: return themeStyles.cursor.drawRouteMode;
                default: return themeStyles.cursor.normalMode;
            }
        }

        const getMapBorderColour = () => {
            switch (mapState.interactionMode) {
                case MapInteractionMode.DrawRoute: return themeStyles.mapBorder.editingMode;
                default: return themeStyles.mapBorder.default;
            }
        }

        let leafletContainer = document.getElementsByClassName('leaflet-container')[0] as HTMLDivElement
        if (leafletContainer && mapState) {
            leafletContainer.style.borderColor = getMapBorderColour();
            leafletContainer.style.cursor = getCursorType();
        }

        setTileLayerOpacity(mapState.isEditMode ? 0.85 : 1)

    }, [mapState, appState])

    useEffect(() => {
        setSelectedMap(mapState.selectedMap)
    }, [mapState.selectedMap])

    if (!MapLayer.hasApiKeys())
        return <></>

    document.addEventListener('dblclick', function (event) {
        event.preventDefault();
    });

    return (
        <>
            <MapContainer
                key={selectedMap.name}
                scrollWheelZoom={true}
                crs={selectedMap.crsOptions.crs}
                attributionControl={true}
                minZoom={selectedMap.crsOptions.minZoom}
                maxZoom={selectedMap.crsOptions.maxZoom}
                zoom={selectedMap.crsOptions.zoom}
                center={appState.mapCentre}
                style={{
                    height: `calc(100vh - ${2 * borderWidth}px)`,
                    border: `solid ${borderWidth}px`,
                    borderColor: '#eaeaea',
                    transition: 'border-color 500ms linear'
                }}
                doubleClickZoom={false} // dblClick throws error on OS Explorer map
                zoomControl={false}
                maxBounds={MapLayer.bounds_UK as L.LatLngBoundsExpression}
                maxBoundsViscosity={1}
            >
                <TileLayer
                    opacity={tileLayerOpacity}
                    className="tile-layer"
                    crossOrigin='anonymous'
                    url={selectedMap.url}
                    attribution={selectedMap.crsOptions.attribution}

                />
                <MyLocationBtn />
                <MapLayersBtn />
                <ClickCircle />
                <AddImagePopup />
                <SearchPointPopup />
                <RoutePolylines />
                <ClickedPolyline id={mapState.clickedPolylineId} />
                <ClickedPolyline id={mapState.addingImage?.routeId ?? 0} />
                <ClickedPolylineFeatures />
                <RouteMarkers />
                <MapContextMenu />
                <DrawnRoute />
                <DrawnRouteMarkers />
                <MapUiEventHandler />
                <ZoomWarning />
            </MapContainer>
            <RouteFinder />
            <AddImage />
            <DrawRouteDetails />
            <SearchResults />
            <MapControls />
        </>
    )


}

export default Map;