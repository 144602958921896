import proj4 from "proj4";
import { IPolylineModel, PolylineType, RoadType } from "../../../models/map/PolylineModel";
import { themeStyles } from "../../../constants/theme";
import { Icons } from "../ui/Icons";
import { LatLng, LatLngBounds, LatLngBoundsExpression } from "leaflet";
import { IRouteFilter, SortOption } from "../../../models/map/RouteFilter";
import { UserModel, UserType } from "../../../models/general/User";

class PolylinePathOptions {
    static DefaultPathOptions: L.PathOptions = {
        fill: false,
        stroke: true,
        weight: 9,
        dashArray: '15',
        color: themeStyles.routeColours.default,

    }
    static DrawRouteModePathOptions: L.PathOptions = {
        ...PolylinePathOptions.DefaultPathOptions,
    }
    static OffRoadPathOptions: L.PathOptions = {
        ...PolylinePathOptions.DefaultPathOptions,
        color: themeStyles.routeColours.offRoad
    }
    static HikingPathOptions: L.PathOptions = {
        ...PolylinePathOptions.DefaultPathOptions,
        color: themeStyles.routeColours.hiking
    }
    static MountainBikePathOptions: L.PathOptions = {
        ...PolylinePathOptions.DefaultPathOptions,
        color: themeStyles.routeColours.mountainBike
    }
    static DirtBikePathOptions: L.PathOptions = {
        ...PolylinePathOptions.DefaultPathOptions,
        color: themeStyles.routeColours.dirtbike,
    }
}

export class MapHelpers {

    static maxRouteNameLength = 60;
    static TRO_Toolip = "A Traffic Regulation Order (TRO) usually means a route has been restricted by the local authority"

    static transformCoords(input: number[]) {
        return proj4("EPSG:27700", "EPSG:4326", input).reverse();
    }

    static getPolylinePathOptions(p: IPolylineModel) {
        switch (p.polylineType) {
            case PolylineType.Unknown: return PolylinePathOptions.DefaultPathOptions;
            case PolylineType.OffRoad: return PolylinePathOptions.OffRoadPathOptions;
            case PolylineType.Hiking: return PolylinePathOptions.HikingPathOptions;
            case PolylineType.MountainBike: return PolylinePathOptions.MountainBikePathOptions;
            case PolylineType.DirtBike: return PolylinePathOptions.DirtBikePathOptions;
            default: return PolylinePathOptions.DefaultPathOptions
        }
    };

    static getRouteTypeLabel(p: PolylineType) {
        switch (p) {
            case PolylineType.Unknown: return "Unknown";
            case PolylineType.OffRoad: return "Off-Road";
            case PolylineType.Hiking: return "Hiking";
            case PolylineType.MountainBike: return "Mountain Bike";
            case PolylineType.DirtBike: return "Dirt Bike";
            default: return "Unknown"
        }
    }

    static getSortOptionLabel(o: SortOption) {
        switch (o) {
            case SortOption.NorthSouth: return "North to South";
            case SortOption.SouthNorth: return "South to North";
            case SortOption.NearMapCentre: return "Near Map Focus";
            case SortOption.SelectedLocation: return "Near Selected Location";
            default: return "Unknown"
        }
    }

    static getRouteTypeIcon(p: PolylineType) {
        switch (p) {
            case PolylineType.Unknown: return Icons.HelpIcon;
            case PolylineType.OffRoad: return Icons.OffRoadIcon;
            case PolylineType.Hiking: return Icons.HikingIcon;
            case PolylineType.MountainBike: return Icons.MountainBikeIcon;
            case PolylineType.DirtBike: return Icons.DirtBikeIcon;
            default: return <></>
        }
    };


    static applyFilter(routes: IPolylineModel[], filter: IRouteFilter): IPolylineModel[] {
        let name = filter.name.toLowerCase()
        routes = routes.filter(r => r.name.toLowerCase().indexOf(name) > -1 || name.length === 0)
        routes = routes.filter(r => r.polylineType & filter.type)
        return routes;
    }

    static applyPendingPolylineFilter(routes: IPolylineModel[], account: UserModel): IPolylineModel[] {
        // Removes pending routes; allows pending routes that belong to user or is user is admin
        return routes.filter(r => !r.pending || (r.pending && (account.routeIDs.indexOf(r.id) > -1 || account.userType === UserType.Admin)))
    }

    static applySorting(routes: IPolylineModel[], filter: IRouteFilter): IPolylineModel[] {
        switch (filter.sort) {
            case SortOption.NorthSouth: return routes.sort(MapHelpers.sortNorthToSouth)
            case SortOption.SouthNorth: return routes.sort(MapHelpers.sortSouthToNorth)
            case SortOption.NearMapCentre: return routes.sort((a, b) => { return MapHelpers.sortDistanceToPointByPolylineCentre(a, b, filter.mapCentre) })
            case SortOption.SelectedLocation: return routes.sort((a, b) => { return MapHelpers.sortDistanceToPointByPolylineCentre(a, b, filter.searchPoint) })
        }
    }


    static getDistanceFromPoint(item: IPolylineModel, point: LatLng): string {
        let bounds = this.getBounds(item.points);
        let centre = bounds.getCenter();
        let distanceMetres = centre.distanceTo(point);
        let distanceKilometres = distanceMetres / 1000;
        return MapHelpers.asKilometreString(distanceKilometres);
    }

    static getPolylineLength(p: IPolylineModel | null) {
        if (!p) {
            return '0km'
        }
        let lengthMetres = 0;

        for (let i = 0; i < p.points.length - 1; i++) {
            const pointA = new LatLng(p.points[i].lat, p.points[i].lng);
            const pointB = new LatLng(p.points[i + 1].lat, p.points[i + 1].lng);
            const segmentLength = pointA.distanceTo(pointB); // Distance in meters
            lengthMetres += segmentLength;
        }

        return MapHelpers.asKilometreString(lengthMetres / 1000)
    }

    static getRouteTypesEnumArray(): PolylineType[] {
        return Object.values(PolylineType)
            .filter(val => !isNaN(Number(val)))
            .filter(val => (Number(val) as PolylineType) !== PolylineType.Unknown)
            .map((val, i) => Number(val) as PolylineType);
    }

    static getSortOptionsEnumArray(): SortOption[] {
        return Object.values(SortOption)
            .filter(val => !isNaN(Number(val)))
            .map((val, i) => Number(val) as SortOption);
    }

    static getRouteColour(type: PolylineType) {
        switch (type) {
            case PolylineType.OffRoad: return themeStyles.routeColours.offRoad;
            case PolylineType.Hiking: return themeStyles.routeColours.hiking;
            case PolylineType.MountainBike: return themeStyles.routeColours.mountainBike;
            case PolylineType.DirtBike: return themeStyles.routeColours.dirtbike;
            default: return 'black';
        }
    }

    static findHighestAndLowestLatitudes(latLngPoints: LatLng[]): { highest: number, lowest: number } {
        if (latLngPoints.length === 0) {
            return { highest: NaN, lowest: NaN }; // Return NaN if array is empty
        }

        let highestLatitude = latLngPoints[0].lat;
        let lowestLatitude = latLngPoints[0].lat;

        for (const point of latLngPoints) {
            if (point.lat > highestLatitude) {
                highestLatitude = point.lat;
            }
            if (point.lat < lowestLatitude) {
                lowestLatitude = point.lat;
            }
        }

        return { highest: highestLatitude, lowest: lowestLatitude };
    }

    static findHighestAndLowestLongitudes(latLngPoints: LatLng[]): { highest: number, lowest: number } {
        if (latLngPoints.length === 0) {
            return { highest: NaN, lowest: NaN }; // Return NaN if array is empty
        }

        let highestLongitude = latLngPoints[0].lng;
        let lowestLongitude = latLngPoints[0].lng;

        for (const point of latLngPoints) {
            if (point.lng > highestLongitude) {
                highestLongitude = point.lng;
            }
            if (point.lng < lowestLongitude) {
                lowestLongitude = point.lng;
            }
        }

        return { highest: highestLongitude, lowest: lowestLongitude };
    }

    static increaseBoundsSouthward(bounds: LatLngBounds): LatLngBounds {

        const latDiff = bounds.getNorthEast().lat - bounds.getSouthWest().lat;
        const doubledLatDiff = latDiff * 2;

        const newSouthWest = new LatLng(bounds.getSouthWest().lat - doubledLatDiff, bounds.getSouthWest().lng)
        return new LatLngBounds(newSouthWest, bounds.getNorthEast())
    }

    static getBounds(coordinates: L.LatLng[], isMobile: boolean = false): LatLngBounds {
        if (coordinates.length === 0) {
            throw Error("No coordinates!");
        }

        let minLat = coordinates[0].lat;
        let maxLat = coordinates[0].lat;
        let minLng = coordinates[0].lng;
        let maxLng = coordinates[0].lng;

        for (const coord of coordinates) {
            minLat = Math.min(minLat, coord.lat);
            maxLat = Math.max(maxLat, coord.lat);
            minLng = Math.min(minLng, MapHelpers.normalizeLongitude(coord.lng));
            maxLng = Math.max(maxLng, MapHelpers.normalizeLongitude(coord.lng));
        }
        let bounds = new LatLngBounds([minLat, minLng], [maxLat, maxLng])
        if (isMobile) {
            // allow room for bottom menu (route finder / route details)
            bounds = this.increaseBoundsSouthward(bounds);
        }

        return bounds

    }

    static routeEnumFlagsToArray(flagValue: PolylineType): PolylineType[] {
        const flagsArray: PolylineType[] = [];
        for (let flag in PolylineType) {
            const flagBit = PolylineType[flag];
            if (typeof flagBit === 'number' && flagBit !== PolylineType.Unknown && (flagValue & flagBit) === flagBit) {
                flagsArray.push(flagBit);
            }
        }
        return flagsArray;
    }

    static startPolylineAnimation(selector: string, animationClass: string) {
        const polylineElements = document.querySelectorAll(selector);
        if (polylineElements.length === 0) {
            return;
        }
        polylineElements.forEach(polylineElement => {
            if (!polylineElement.classList.contains(animationClass)) {
                polylineElement.classList.add(animationClass);
                const onAnimationEnd = () => {
                    polylineElement.classList.remove(animationClass);
                    polylineElement.removeEventListener('animationend', onAnimationEnd);
                };
                polylineElement.addEventListener('animationend', onAnimationEnd);
            }
        })
    }

    static getRoadTypeLabel(r: RoadType | null): string {
        if (!r)
            return '';
        switch (r) {
            case RoadType.PublicGreenLane: return 'Public Green Lane';
            case RoadType.Permissive: return 'Permitted Private';
            case RoadType.Private: return 'Private';
            default: return 'Unknown';
        }
    }

    static getGoogleMapsLink(pos: LatLng): string {
        return `https://www.google.com/maps?q=${pos.lat},${pos.lng}`
    }

    private static normalizeLongitude(lng: number): number {
        // Adjust longitude to fall within the range [-180°, 180°]
        while (lng > 180) {
            lng -= 360;
        }
        while (lng < -180) {
            lng += 360;
        }
        return lng;
    }



    private static sortNorthToSouth(lineA: IPolylineModel, lineB: IPolylineModel): number {
        let a = MapHelpers.findCenter(lineA.points);
        let b = MapHelpers.findCenter(lineB.points);
        // Compare latitude values
        if (a.lat < b.lat) return 1;
        if (a.lat > b.lat) return -1;
        // If latitudes are equal, use longitude for tie-breaker
        if (a.lng < b.lng) return -1;
        if (a.lng > b.lng) return 1;
        return 0;
    }

    private static sortSouthToNorth(lineA: IPolylineModel, lineB: IPolylineModel): number {
        let a = MapHelpers.findCenter(lineA.points);
        let b = MapHelpers.findCenter(lineB.points);
        // Compare latitude values
        if (a.lat < b.lat) return -1;
        if (a.lat > b.lat) return 1;
        // If latitudes are equal, use longitude for tie-breaker
        if (a.lng < b.lng) return -1;
        if (a.lng > b.lng) return 1;
        return 0;
    }

    private static findCenter(latLngArray: LatLng[]): LatLng {
        if (latLngArray.length === 0) {
            throw Error("No points given!");
        }

        // Calculate average latitude and longitude
        const avgLat = latLngArray.reduce((sum, point) => sum + point.lat, 0) / latLngArray.length;
        const avgLng = latLngArray.reduce((sum, point) => sum + point.lng, 0) / latLngArray.length;

        return new LatLng(avgLat, avgLng);
    }

    private static findNearestPoint(points: L.LatLng[], p: LatLng) {
        let _points = [...points]
        // Need to clone because sort() rearranges lat long points in place!

        _points.sort((a: L.LatLng, b: L.LatLng) => {
            let latLngA = new LatLng(a.lat, a.lng);
            let latLngB = new LatLng(b.lat, b.lng);
            return latLngA.distanceTo(p) < latLngB.distanceTo(p) ? -1 : 1;
        })
        return _points[0]
    }

    static sortDistanceToPointByNearestNode(lineA: IPolylineModel, lineB: IPolylineModel, point: LatLng | undefined): number {
        if (!point)
            return 0;

        let nearestPointLineA = MapHelpers.findNearestPoint(lineA.points, point);
        let nearestPointLineB = MapHelpers.findNearestPoint(lineB.points, point);
        let distanceToPointA = point.distanceTo(nearestPointLineA);
        let distanceToPointB = point.distanceTo(nearestPointLineB);
        return distanceToPointA - distanceToPointB
    }
    private static sortDistanceToPointByPolylineCentre(lineA: IPolylineModel, lineB: IPolylineModel, point: LatLng | null): number {
        if (!point)
            return 0;

        let a = MapHelpers.findCenter(lineA.points);
        let b = MapHelpers.findCenter(lineB.points);
        let distanceToPointA = point.distanceTo(a);
        let distanceToPointB = point.distanceTo(b);
        return distanceToPointA - distanceToPointB
    }


    private static asKilometreString(distanceKilometres: number) {
        if (distanceKilometres >= 10) {
            return `${Math.round(distanceKilometres)}km`
        }
        else {
            distanceKilometres *= 10;
            distanceKilometres = Math.round(distanceKilometres);
            distanceKilometres /= 10;
            return `${distanceKilometres}km`
        }
    }
}