import { useMap, useMapEvents } from "react-leaflet"
import { themeStyles } from "../../../constants/theme"
import { Button, Dropdown, MenuProps, Space } from "antd"
import { Icons } from "./Icons"
import { IMapLayerConfig, MapLayer } from "../helpers/MapLayer"
import { useMapContext } from "../../../contexts/MapContext"
import { MapLayerType } from "../../../hooks/useCustomCRSFor27700Projection"
import ReactGA from 'react-ga4'

const MapLayersBtn = () => {

    const { mapState, updateMapState } = useMapContext()

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
    };

    const map = useMap();

    const mapLayerChanged = (e: any, config: IMapLayerConfig) => {
        e.domEvent.stopPropagation()
        config.crsOptions.center = map.getCenter()
        if (mapState.selectedMap.crsOptions.layerType === config.crsOptions.layerType) {
            //Same layer type
            config.crsOptions.zoom = map.getZoom();
        }
        else {
            switch (config.crsOptions.layerType) {
                case MapLayerType.EPSG27700:
                    // Map is changing from OS to MapBox
                    config.crsOptions.zoom = map.getZoom() - MapLayer.EPSG3857_Upscale;
                    break;
                case MapLayerType.EPSG3857:
                    // Map is changing from MapBox to OS
                    config.crsOptions.zoom = map.getZoom() + MapLayer.EPSG3857_Upscale;
                    break;
            }
        }
        MapLayer.UpdateMapLayerLimits(config);
        updateMapState({
            ...mapState,
            selectedMap: config
        })
        ReactGA.event({
            category: 'Map Interaction',
            action: 'Map Layer Selected',
            label: config.name
        });
    }

    const items: MenuProps['items'] = MapLayer.getMapOptions().map((opt, i) => {
        return {
            label: <Button
                type="text"
                style={{ textAlign: 'left' }}
                className="full-width"

                icon={Icons.MapLayersIcon}>
                {opt.name}
            </Button>,
            key: `map-layer-opt-${i}`,
            icon: opt.icon,
            onClick: (e) => mapLayerChanged(e, opt)
        }
    })

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const style: React.CSSProperties = {
        position: 'fixed',
        left: '15px',
        top: '65px',
        zIndex: 1002,
        fontSize: '25px',
        backgroundColor: 'white',
        color: themeStyles.colorPrimary,
        boxShadow: '0 0 15px 1px grey',
        height: '40px',
        width: '40px'
    }

    return <Dropdown menu={menuProps} trigger={["hover"]} >
        <Button
            style={style}
            onClick={(e) => { e.stopPropagation() }}
            icon={Icons.MapLayersIcon}
        />
    </Dropdown>
}

export default MapLayersBtn