import { Popup } from "react-leaflet"
import { useMapContext } from "../../../contexts/MapContext";
import { Icons } from "../ui/Icons";
import '../../../css/map.css'

export const AddImagePopup = () => {

    const { mapState, updateMapState } = useMapContext();

    if (!mapState.addingImage?.position) {
        return <></>
    }

    return <Popup
        autoPan={false}
        className="primary-popup"
        closeOnClick={false}
        position={mapState.addingImage.position}
        autoClose={false}
        closeButton={false}
    >
        <div>
            {Icons.AddImageIcon}
        </div>
    </Popup>
}

export default AddImagePopup