import { useState } from "react";
import { useMapEvents } from "react-leaflet"
import { Tag } from "antd";
import { themeStyles } from "../../../constants/theme";
import { useAppContext } from "../../../contexts/AppContext";
import { MapLayer } from "../helpers/MapLayer";



const ZoomWarning = () => {

    const [show, setShow] = useState(false);
    const { appState, updateAppState } = useAppContext();

    const map = useMapEvents({
        zoomend() {
            setShow(MapLayer.outsideRenderLimit(map.getZoom()))
            updateAppState({
                ...appState,
                mapCentre: map.getCenter()
            })
        },
        moveend() {
            updateAppState({
                ...appState,
                mapCentre: map.getCenter()
            })
        }
    });

    const style: React.CSSProperties = {
        position: 'fixed',
        bottom: '100px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1001,
        fontSize: '25px',
        height: '28px'
    }

    return show
        ? <Tag color={themeStyles.colorWarn} style={style}>
            Zoom in to view routes
        </Tag>
        : <></>
}

export default ZoomWarning