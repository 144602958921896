import { useState, useRef, useMemo } from "react"
import { Marker } from "react-leaflet"
import L from 'leaflet'
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { themeConstants } from "../../../constants/theme";

interface IProps {
    index: number,
    position: L.LatLng
    onMarkerDragEnd: (index: number, pos: L.LatLng) => void
}

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [themeConstants.iconAnchor.x, themeConstants.iconAnchor.y]
});

L.Marker.prototype.options.icon = DefaultIcon

function DraggableMarker(props: IProps) {

    const [pos, setPosition] = useState(props.position)

    const markerRef = useRef<any>(null)

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    props.onMarkerDragEnd(props.index, marker.getLatLng())
                }
            },
            // contextmenu(e: any) {
            //     e.originalEvent.preventDefault();
            //     console.log('marker right click')
            // }
        }),
        [props],
    )

    return (
        <Marker
            eventHandlers={eventHandlers}
            position={pos}
            draggable={true}
            ref={markerRef}>
        </Marker>
    )
}

export default DraggableMarker