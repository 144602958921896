
export interface IFBLoginResponse {
    accessToken: string;
    data_access_expiration_time: number;
    expiresIn: number;
    grantedScopes: string;
    graphDomain: string;
    signedRequest: string;
    userID: string;
}

export interface IFBProfileData {
    id: string
    name: string
    email: string
}

export enum OAuthType {
    Facebook,
    Google
}

export interface OauthVerificationRequest {
    name: string;
    email: string;
    accessToken: string;
    oAuthType: OAuthType;
}

export interface IGoogleLoginResponse {
    access_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
}