import { CircleMarker, useMapEvents } from "react-leaflet";
import { useMapContext } from "../../../contexts/MapContext";

/**A basic circle for user feedback when clicking freely on map*/
export const ClickCircle = () => {

    const { mapState, updateMapState } = useMapContext();

    const setSearchFilterMapCentre = () => {
        updateMapState({
            ...mapState,
            filter: {
                ...mapState.filter,
                mapCentre: map.getCenter()
            }
        })
    }

    const map = useMapEvents({

        moveend(e) {
            setSearchFilterMapCentre();
        },
        mousedown(e) {
            updateMapState({
                ...mapState,
                clickCirclePos: null,
                showContextMenu: false
            })
        },
        dblclick(e) {
            const zoom = map.getZoom() == map.getMaxZoom()
                ? map.getZoom()
                : map.getZoom() + 1;
            map.flyTo(e.latlng, zoom)
            e.originalEvent.preventDefault();
        }
    })

    if (!mapState.clickCirclePos)
        return <></>

    return (
        <CircleMarker center={mapState.clickCirclePos} radius={5} />
    )
}

export default ClickCircle