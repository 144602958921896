import { LatLngExpression } from "leaflet"
import { Marker, Polyline, useMapEvents } from "react-leaflet"
import { useMapContext } from "../../../contexts/MapContext";
import { MapInteractionMode } from "../../../models/map/MapState";
import { IPolylineModel, PolylineModel, PolylineType } from "../../../models/map/PolylineModel";
import { useEffect } from "react";
import { MapHelpers } from "../helpers/MapHelpers";

export const DrawnRoute = () => {
    const { mapState, updateMapState } = useMapContext();


    const map = useMapEvents({

        click(e) {
            if (mapState.interactionMode !== MapInteractionMode.DrawRoute) {
                return;
            }

            let polyline = mapState.editingPolyline
                ? { ...mapState.editingPolyline }
                : new PolylineModel(PolylineType.Unknown)

            polyline.points.push(e.latlng);

            updateMapState({
                ...mapState,
                editingPolyline: polyline
            })
        },
        contextmenu(e) {
            e.originalEvent.preventDefault();
        }
    })

    const startAnimation = () => {
        MapHelpers.startPolylineAnimation('.animated-polyline', 'animate-dash');
    }

    useEffect(() => {
        startAnimation()
    }, []);


    const positions: LatLngExpression[] = mapState.editingPolyline?.points.map((p, i) => [p.lat, p.lng] as LatLngExpression) ?? []

    if (!mapState?.editingPolyline)
        return <></>



    if (positions.length === 1) {
        return <Marker
            draggable
            position={mapState.editingPolyline.points[0]}>
        </Marker>
    }

    const polylineOptions = MapHelpers.getPolylinePathOptions(mapState.editingPolyline)
    const zoom = map.getZoom();
    return <>
        <Polyline
            className="animated-polyline"
            pathOptions={{
                ...polylineOptions,
                weight: zoom > mapState.selectedMap.crsOptions.zoom ? zoom : mapState.selectedMap.crsOptions.zoom,
                dashArray: zoom > mapState.selectedMap.crsOptions.zoom ? '15' : '',

            }}
            positions={positions} />
    </>
}

export default DrawnRoute