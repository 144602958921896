import { PolylineType } from "./PolylineModel";

export enum SortOption {
    NorthSouth,
    SouthNorth,
    NearMapCentre,
    SelectedLocation
}

export interface IRouteFilter {
    name: string,
    /** PolylineType uses flags so this can be assigned multiple values */
    type: PolylineType
    sort: SortOption
    searchPoint: L.LatLng | null,
    mapCentre: L.LatLng | null
}

export class RouteFilter implements IRouteFilter {
    name: string = '';
    type: PolylineType = PolylineType.OffRoad | PolylineType.MountainBike | PolylineType.Hiking | PolylineType.DirtBike;
    sort: SortOption = SortOption.NearMapCentre;
    searchPoint: L.LatLng | null = null;
    mapCentre: L.LatLng | null = null
}