import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface IWindowContext {
    isMobile: boolean;
    viewport: { w: number, h: number }
}

const WindowContext = createContext<IWindowContext>({
    isMobile: false,
    viewport: { w: 0, h: 0 }
});

export const mobileBreakPoint = 768

export const WindowContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [viewport, setViewPort] = useState({ w: window.innerWidth, h: window.innerHeight })

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < mobileBreakPoint); // Change 768 to your desired breakpoint
            setViewPort({ w: window.innerWidth, h: window.innerHeight })
        }

        // Initial check on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <WindowContext.Provider value={{ isMobile, viewport }}>
            {children}
        </WindowContext.Provider>
    );
};

export const useWindowContext = () => useContext(WindowContext);