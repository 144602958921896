import { Button, Space } from "antd";
import { MapActions } from "../helpers/MapActions";
import { Icons } from "../ui/Icons";

export const NormalModeContextMenu = () => {

    return <Space.Compact direction={'vertical'}>
        <Button
            icon={Icons.DrawRouteModeIcon}
            onClick={(e) => { e.stopPropagation(); MapActions.beginDrawRouteMode() }}
            size="middle"
            type="primary">
            Draw Route
        </Button>
        <Button
            icon={Icons.AddImageIcon}
            onClick={(e) => { e.stopPropagation(); MapActions.addImageClicked() }}
            size="middle"
            type="default">
            Add Photo
        </Button>
        <Button
            icon={Icons.RouteFinderIcon}
            onClick={(e) => { e.stopPropagation(); MapActions.setSelectedSearchLocation() }}
            size="middle"
            type="default">
            Set Route Finder
        </Button>
    </Space.Compact>
}

export default NormalModeContextMenu