import { useMapContext } from "../../../contexts/MapContext";
import { PolylineModel } from "../../../models/map/PolylineModel";
import DraggableMarker from "./DraggableMarker";

export const DrawnRouteMarkers = () => {

    const { mapState, updateMapState } = useMapContext();

    const handleMarkerDragEnd = (index: number, pos: L.LatLng) => {

        let polyline = { ...mapState.editingPolyline } as PolylineModel;

        polyline.points[index] = pos;

        updateMapState({
            ...mapState,
            editingPolyline: polyline
        })
    }

    if (!mapState.editingPolyline)
        return <></>

    return <>
        {
            mapState.editingPolyline.points.map((p, i) =>
                <DraggableMarker
                    index={i}
                    key={`${p.lat}-${p.lng}-${i}`}
                    position={p}
                    onMarkerDragEnd={handleMarkerDragEnd} />)
        }
    </>
}


export default DrawnRouteMarkers