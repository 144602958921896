import * as L from "leaflet";
import "proj4leaflet";
import { MapHelpers } from "../components/map/helpers/MapHelpers";
import proj4 from "proj4";
import { MapLayer } from "../components/map/helpers/MapLayer";

export enum MapLayerType {
    /**OS Map */
    EPSG27700,
    /**MapBox */
    EPSG3857
}

export interface ICustomMapCrsOptions {
    zoom: number
    crs: L.CRS,
    minZoom: number,
    maxZoom: number,
    center: L.LatLng,
    /**The zoom level the map should change to during flyTo */
    flyToZoom: number,
    layerType: MapLayerType
    attribution: string
}

const MapBoxAttribution = `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`;
const UIconsAttribution = `UIcons by <a href="https://www.flaticon.com/uicons">Flaticon</a>`


export function useCustomCRSForEPSG3857Projection(): ICustomMapCrsOptions {
    const options: ICustomMapCrsOptions = {
        crs: L.CRS.EPSG3857,
        minZoom: MapLayer.baseMinZoom + MapLayer.EPSG3857_Upscale,
        maxZoom: MapLayer.baseMaxZoom + MapLayer.EPSG3857_Upscale,
        center: MapLayer.center_UK,
        zoom: MapLayer.baseZoom + MapLayer.EPSG3857_Upscale,
        flyToZoom: MapLayer.baseZoom + MapLayer.EPSG3857_Upscale,
        layerType: MapLayerType.EPSG3857,
        attribution: MapBoxAttribution
    };
    return options;
}

export function useCustomCRSFor27700Projection(): ICustomMapCrsOptions {

    const crs = new L.Proj.CRS("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs", {
        resolutions: [896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75],
        origin: [-238375.0, 1376256.0]
    });



    const options: ICustomMapCrsOptions = {
        crs: crs,
        minZoom: MapLayer.baseMinZoom,
        maxZoom: MapLayer.baseMaxZoom,
        center: MapLayer.center_UK,
        zoom: MapLayer.baseZoom,
        flyToZoom: MapLayer.baseZoom,
        layerType: MapLayerType.EPSG27700,
        attribution: UIconsAttribution
    };


    return options;
}