import { Col, Divider, Row } from "antd";
import Title from "antd/es/typography/Title";
import '../css/pages.css'
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from 'react-ga4'

function AboutPage() {
    ReactGA.send({ hitType: "pageview", page: "/about", title: "About" });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return <>
        <div className="container">

            <Title>About off-road-map.uk</Title>
            <Divider />
            <Row>
                <Col span={24}>

                    <p>Welcome to off-road-map.uk, your ultimate destination for finding, viewing, and adding off-road routes for driving, dirt-biking, mountain biking, and hiking adventures in the UK!</p>

                    <h2>Our Mission</h2>
                    <p>At off-road-map.uk, our mission is to provide a comprehensive platform for outdoor enthusiasts to discover and share their favourite off-road routes across the beautiful landscapes of the United Kingdom.</p>

                    <h2>Features</h2>
                    <p>Our website offers the following features:</p>
                    <ul>
                        <li>Browse and search for off-road routes based on your preferred activity: driving, dirt-biking, mountain biking, or hiking.</li>
                        <li>View detailed information about each route, including distance and accessibility.</li>
                        <li>A choice of maps, including satellite, outdoor terrain, and the Ordnance Survey Explorer map</li>
                        <li>Add new off-road routes to our database.</li>
                        <li>Upload and attach images to routes to provide visual insights into the scenery and trail conditions.</li>
                    </ul>

                    <h2>Get Started</h2>
                    <p>Ready to explore the great outdoors? Sign up for a free account today and start discovering off-road routes for your next adventure!</p>

                    <h2>Privacy Policy</h2>
                    <p>You can view our privacy statement by clicking <Link to="/privacy">here</Link>.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions, suggestions, or feedback, please don't hesitate to contact us at  <a href="mailto:offroadmapuk@gmail.com">offroadmapuk@gmail.com</a>.</p>
                </Col>
            </Row>
        </div>

    </>
}

export default AboutPage