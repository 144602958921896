import { ApiResult, IApiResult } from "../models/api/apiResult";
import { IPolylineModel } from "../models/map/PolylineModel";
import { getUserTokenCookie, handleApiResultToken } from "./clientHelpers";

const savePolylineUrl = 'map/save-polyline'
const updatePolylineUrl = 'map/update-polyline'
const deletePolylineUrl = 'map/delete-polyline'
const approvePolylineUrl = 'map/approve-polyline'
const getAllPolylinesUrl = 'map/get-all-polylines'

export const savePolyline = async (polyline: IPolylineModel): Promise<IApiResult<IPolylineModel>> => {
    try {

        const response = await fetch(process.env.REACT_APP_BASE_URL + savePolylineUrl, {
            method: 'POST',
            body: JSON.stringify(polyline),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<IPolylineModel> = json as IApiResult<IPolylineModel>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<IPolylineModel>(false);
    }
}

export const updatePolyline = async (polyline: IPolylineModel): Promise<IApiResult<IPolylineModel>> => {
    try {

        const response = await fetch(process.env.REACT_APP_BASE_URL + updatePolylineUrl, {
            method: 'POST',
            body: JSON.stringify(polyline),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<IPolylineModel> = json as IApiResult<IPolylineModel>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<IPolylineModel>(false);
    }
}

export const getAllPolylines = async (): Promise<IApiResult<IPolylineModel[]>> => {
    try {
        const response = await fetch(process.env.REACT_APP_BASE_URL + getAllPolylinesUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            //credentials: 'include'
        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<IPolylineModel[]> = json as IApiResult<IPolylineModel[]>;
            return apiResult;
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<IPolylineModel[]>(false);
    }
}

export const deletePolyline = async (id: number): Promise<IApiResult<number>> => {
    try {

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${deletePolylineUrl}/${id}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<number> = json as IApiResult<number>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<number>(false);
    }
}

export const approveRoute = async (id: number): Promise<IApiResult<number>> => {
    try {

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${approvePolylineUrl}/${id}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${getUserTokenCookie()}`
            },

        });
        if (response.ok) {
            const json = await response.json();
            const apiResult: IApiResult<number> = json as IApiResult<number>;
            return handleApiResultToken(apiResult);
        }
        else {
            throw new Error('Api response was not OK')
        }
    }
    catch (error) {
        console.error(error);
        return new ApiResult<number>(false);
    }
}